import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constant";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon, UserPlusIcon, XCircleIcon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import useWindowSize from "hooks/useWindowResize";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useGetPropertyQuery, useGetPropertyStatsQuery, useLazyGetPropertyAvailabilityByDatesQuery, useLazyGetPropertyAvailabilityByRangeQuery } from "features/property/propertyApiSlice";
import Skeleton from "react-loading-skeleton";
import MobileFooterSticky from "../(components)/MobileFooterSticky";
import GoogleMapReact from "google-map-react";
import Marker from "components/AnyReactComponent/Marker";
import moment from "moment";
import SectionGrid from "containers/ListingExperiencesPage/SectionGrid";
import { GuestsObject } from "components/HeroSearchForm/type";
import InputBar from "components/InputBar/InputBar";
import { useDispatch } from "react-redux";
import { selectRange, setBooking, setBookings } from "features/bookings/bookingsSlice";
import { useLazyFilterExperienceQuery, useLazyGetExperiencesNearbyQuery } from "features/experience/experienceApiSlice";
import { useSelector } from "react-redux";
import { selectExperiencesList, setExperiencesList } from "features/experience/experienceSlice";
import ModalLogin from "../(components)/ModalLogin";
import { selectCurrentclient } from "features/client/clientSlice";
import { selectCurrentToken } from "features/clientAuth/authClientSlice";
import { selectAvailability, setAvailability, setproperty } from "features/property/propertySlice";
import { setEvents } from "features/calendar/calendarSlice";
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { toast } from "react-toastify";
import { useGetReviewByPropertyUUIDQuery, useLazyGetReviewByPropertyUUIDQuery } from "features/review/reviewApiSlice";
import Pagination from "shared/Pagination/Pagination";

const returnMoodColor = (mood: string) => {
  switch (mood) {
    case "authentic":
      return "green";
    case "business":
      return "blue";
    case "comfort":
      return "yellow";
    default:
      return "red";
  }
}
const StayDetailPageContainer: FC<{}> = () => {
  // STATES
  const [startDate, setStartDate] = useState<Date>(moment().add(1, "day").toDate());
  const [endDate, setEndDate] = useState<Date>(moment().add(3, "day").toDate());
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [showFooterSticky, setShowFooterSticky] = useState(true);
  const [radius, setRadius] = useState<number>(25);

  const [guests, setGuests] = useState<GuestsObject>({
    guestAdults: 1,
    guestChildren: 0,
    guestInfants: 0,
  });

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);

  let [isImageFullScreen, setIsImageFullScreen] = useState("");

  // PARAMS
  const id = useParams().id;
  const { startDateParam, endDateParam } = useParams();

  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { width, height } = useWindowSize();

  // QUERIES
  const { data: listing, isLoading, isSuccess } = useGetPropertyQuery(id);
  const [fetchReviews, { data: reviews, isLoading: isLoadingReviews, isSuccess: isSuccessReviews }] = useLazyGetReviewByPropertyUUIDQuery();
  const [fetchExperiencesNearby, { data: experiencesNearby, isLoading: isLoadingExperiences, isSuccess: isSuccessExperiencesNearby }] = useLazyGetExperiencesNearbyQuery({});
  const { data: propertyStats, isLoading: isLoadingStats } = useGetPropertyStatsQuery(id);
  const [checkIfPropertyIsAvailable, { data: propertyIsAvailable, isLoading: isCheckingPropertyAvailability }] = useLazyGetPropertyAvailabilityByDatesQuery({});
  const [checkAvailabilityInRange, { data: rangeAvailability, isLoading: isLoadingRangeAvailability }] = useLazyGetPropertyAvailabilityByRangeQuery({});

  // SELECTORS
  const experiencesList = useSelector(selectExperiencesList);
  const client_token = useSelector(selectCurrentToken);
  const range = useSelector(selectRange)

  // FETCH FUNCTIONS
  const handleFetchExperiences = async () => {
    try {
      const res = await fetchExperiencesNearby({
        latitude: listing.property?.latitude,
        longitude: listing.property?.longitude,
        radius: radius,
      }).unwrap();
      dispatch(setExperiencesList(res));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchReviews({ property_uuid: id, page: 1 });
  }, [id])

  const handleStartBooking = async (skipClientControl: boolean) => {
    // set booking data to redux
    if (!client_token && !skipClientControl) {
      setShowLoginModal(true);
    }
    else {
      setShowLoginModal(false);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      const totalGuests = (guests.guestAdults || 0) + (guests.guestChildren || 0) + (guests.guestInfants || 0);
      if (totalGuests > listing.property?.guests_included) {
        toast.error(`Questo alloggio può ospitare al massimo ${listing.property?.guests_included} ospiti`);
        return;
      }
      const weekday_price = Number(listing.property?.weekday_price);
      const weekend_price = Number(listing.property?.weekend_price);
      const cleaning_fee_per_night = Number(listing.property?.cleaning_fee) || 0;
      const totalDays = moment(endDate).diff(moment(startDate), "days");
      let price = 0;
      for (let i = 0; i < totalDays; i++) {
        const day = moment(startDate).add(i, "days").get("day");
        // if day is sunday or satuday
        if (day === 6 || day === 0) {
          price += weekend_price;
        } else {
          price += weekday_price;
        }
      }

      // Aggiungi le spese di pulizia per ogni notte
      const total_cleaning_fee = cleaning_fee_per_night * totalDays;
      const finalPrice = price + total_cleaning_fee + (price * 0.15) + (price * 0.15 * 0.22);

      const bookingData = {
        property: listing.property,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        guests: totalGuests,
        price: finalPrice,
        cleaning_fee: total_cleaning_fee,
        events: [],
        type: "stay",
      };
      dispatch(setBooking(bookingData));
      setShowFooterSticky(false);
      toast.success("Prenotazione aggiunta al tuo viaggio");
    }
  }

  const handleFetchAvailability = async () => {
    try {
      const availability = await checkIfPropertyIsAvailable(
        {
          property_uuid: listing.property.property_uuid,
          check_in: moment(startDate).format("YYYY-MM-DD"),
          check_out: moment(endDate).format("YYYY-MM-DD")
        })
        .unwrap();
    } catch (error) {

    }
  }

  const handleFetchAvailabilityInRangeByInitialDate = async () => {
    try {

      const today = moment().format("YYYY-MM-DD");
      const month = moment().format("MM");
      const year = moment().format("YYYY");
      const nextMonth = moment().add(1, "month").format("MM");

      const response = await checkAvailabilityInRange
        ({
          property_uuid: listing.property.property_uuid,
          start_month: month,
          end_month: nextMonth,
          year: year
        }).unwrap();

      dispatch(setAvailability(response));
    } catch (error) {
    }
  }

  // EVENT HANDLERS
  const onMonthChange = useCallback(async (date: Date) => {
    try {
      // get month and next month from date
      const month = moment(date).format("MM");
      const year = moment(date).format("YYYY");
      const nextMonth = moment(date).add(1, "month").format("MM");

      const response = await checkAvailabilityInRange
        ({
          property_uuid: listing.property.property_uuid,
          start_month: month,
          end_month: nextMonth,
          year: year
        }).unwrap();

      dispatch(setAvailability(response));
    } catch (error) {
    }
  }, [/* dipendenze */]);


  const onChangeGuests = (guests: any) => {
    const maxGuests = listing.property?.guests_included || 1;
    if (guests.guestAdults + guests.guestChildren > maxGuests) {
      alert(`Questo alloggio può ospitare al massimo ${maxGuests} ospiti`);
    }
    setGuests(guests);
  }

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  // EFFECTS
  useEffect(() => {
    handleFetchAvailabilityInRangeByInitialDate();
  }, [listing?.property?.property_uuid])

  useEffect(() => {
    //dispatch(setBookings([]));
  }, [])

  useEffect(() => {
    if (listing && isSuccess && !isLoading && !isSuccessExperiencesNearby) {
      handleFetchExperiences();
    }
    if (!isLoading && isSuccess) {
      dispatch(setproperty(listing.property));
    }
  }, [isSuccess])

  useEffect(() => {
    if (startDateParam && endDateParam) {
      setStartDate(moment(startDate).toDate());
      setEndDate(moment(endDate).toDate());
    }

  }, []);

  useEffect(() => {
    if (listing && listing.property) {
      document.title = listing.property.name;
    }
  }, [listing])



  // COMPONENTS
  const renderSection2 = () => {
    return (
      <div className='listingSection__wrap'>
        <h2 className='text-2xl font-semibold'>Descrizione</h2>
        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
        <div className='text-neutral-6000 dark:text-neutral-300 max-w-full'>
          <span className="max-w-full break-words">
            {listing.property?.description}
          </span>
        </div>
      </div>
    );
  };

  const renderSectionExperiences = () => {
    if (isLoadingExperiences) {
      return (
        Array(3).fill(0).map((_, index) => (
          <div key={index} className="w-full md:w-1/3 lg:w-1/4 p-2">
            <Skeleton className="w-full h-[20rem]" />
          </div>
        ))
      )
    }
    return (
      <div className='listingSection__wrap'>
        <h2 className='text-2xl font-semibold'>Esperienze</h2>
        <div className='text-neutral-6000 dark:text-neutral-300 max-w-full'>
          <SectionGrid data={experiencesList} latitude={listing.property?.latitude} longitude={listing.property?.longitude} />
        </div>
      </div>
    );
  };

  const renderSectionGallery = () => {
    return (
      <div className='listingSection__wrap mt-[2rem] mb-[2rem]'>
        <div className="w-5/6  mx-auto space-y-8 lg:space-y-10 lg:pr-10">
          <h2 className='text-2xl font-semibold'>Foto della struttura</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
          <div className='text-neutral-6000 dark:text-neutral-300'>
            <span>
              Qui puoi vedere le foto della struttura e di tutte le sue stanze
              e aree comuni. Se necessiti di ulteriori informazioni, puoi sempre chiedere all'host delle foto aggiuntive
            </span>
          </div>
        </div>
        <Carousel
        >
          <div onClick={() => setIsImageFullScreen(listing.property?.cover)} className="max-w-full max-h-[19rem]">
            <img src={listing.property?.cover} />
          </div>
          {
            listing.property?.media.map((item: string, index: number) => (
              <div onClick={() => setIsImageFullScreen(item)} key={index} className="max-w-full max-h-[19rem]">
                <img src={item} />
              </div>
            ))
          }
        </Carousel>
        <Transition appear show={isImageFullScreen.length > 0} as={Fragment}>
          <Dialog
            as='div'
            className='fixed inset-0 z-50 overflow-y-auto'
            onClose={() => null}
          >
            <div className='min-h-screen px-4 text-center flex items-center justify-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-75' />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <div>
                  <button
                    onClick={() => setIsImageFullScreen("")}
                    className="absolute top-5 right-5"
                  >
                    <XCircleIcon className="w-6 h-6 text-white" />
                  </button>
                  <img src={isImageFullScreen} className="w-full h-auto" alt="" />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const getIconFromAmenities = (name: string) => {
    const icons = [
      { name: "Wifi", icon: "la-wifi" },
      { name: "tv", icon: "la-tv" },
      { name: "aria condizionata", icon: "la-fan" },
      { name: "asciugatore", icon: "la-fan" },
      { name: "ventilatore", icon: "la-fan" },
      { name: "Asciugacapelli", icon: "la-fan" },
      { name: "lavastoviglie", icon: "la-fan" },
      { name: "Prodotti per la cura personale", icon: "la-bath" },
      { name: "Scrivania", icon: "la-desktop" },
      { name: "Cassaforte", icon: "la-lock" },
      { name: "Armadio", icon: "la-tshirt" }
    ]
    const icon = icons.find(item => item.name.toLowerCase() === name.toLowerCase());
    return icon?.icon;
  }

  const renderSection3 = () => {
    return (
      <div>
        <div className='grid grid-cols-2 md:grid-cols-6 gap-4 text-sm text-neutral-700 dark:text-neutral-300  '>
          {listing.property?.amenities.filter((_: any, i: number) => i < 10).map((item: string) => (
            <div
              key={item}
              className='flex items-center justify-center space-x-3 border border-slate-300 rounded-full px-3 py-2'>
              <i className={`text-3xl las ${getIconFromAmenities(item)}`}></i>
              <span className=' '>{item}</span>
            </div>
          ))}
          {/* ----- */}
          <div>
            {
              listing.property?.amenities.length > 10 &&
              <ButtonSecondary onClick={openModalAmenities}>
                Vedi altri {listing.property?.amenities.length - 10} servizi
              </ButtonSecondary>
            }
          </div>
          {renderMotalAmenities()}
        </div>
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={closeModalAmenities}>
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'>
              <div className='inline-block py-8 h-screen w-full max-w-4xl'>
                <div className='inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <h3
                      className='text-lg font-medium leading-6 text-gray-900'
                      id='headlessui-dialog-title-70'>
                      Amenities
                    </h3>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className='px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200 z-[90] relative'>
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className='flex flex-col flex-wrap sm:flex-row items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8 '>
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>

                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection6 = () => {
    return (
      <div className='listingSection__wrap'>
        {/* HEADING */}
        <h2 className='text-2xl font-semibold'>Recensioni ({reviews?.data?.length * reviews?.totalPages} recensioni)</h2>
        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>

        {/* comment */}
        <div className='divide-y divide-neutral-100 dark:divide-neutral-800'>
          {
            reviews?.data?.map((item: any, index: number) => (
              <CommentListing key={index} data={item} />
            ))
          }
          <div className='pt-8 w-full flex items-center justify-center'>
            <Pagination
              totalPages={reviews?.totalPages || 0}
              currentPage={reviews?.currentPage || 0}
              onPageChange={(page) => fetchReviews({ property_uuid: id, page })}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className='listingSection__wrap'>
        <div className="w-full md:w-3/4">
          {/* HEADING */}
          <div>
            <h2 className='text-2xl font-semibold'>Location</h2>
            <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
              {listing.property?.address} {listing.property?.building} ,{listing.property?.city}, {listing.property?.country}
            </span>
          </div>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700 mt-4' />

          {/* MAP */}
          <div className='aspect-w-5 mt-4 md:mt-4 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0'>
            <div className='rounded-xl overflow-hidden z-0'>
              {
                listing.property?.latitude && listing.property?.longitude &&
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCVgu-JWoz3RsKhafHgSffRNKPBngBETxs",
                  }}
                  yesIWantToUseGoogleMapApiInternals
                  defaultZoom={18}
                  options={{
                    fullscreenControl: false,
                    zoomControl: true,
                    minZoom: 10,
                    maxZoom: 20
                  }}
                  center={{
                    lat: Number(listing.property.latitude),
                    lng: Number(listing.property.longitude),
                  }}
                >
                  <Marker 
                    lat={Number(listing.property?.latitude)}
                    lng={Number(listing.property?.longitude)}
                    className="absolute transform -translate-x-1/2 -translate-y-1/2"
                  />
                </GoogleMapReact>
              }
            </div>
          </div>
        </div>


      </div >
    );
  };

  const renderSection8 = () => {
    return (
      <div className='listingSection__wrap '>
        <div className="w-full space-y-2 md:w-3/4">
          {/* HEADING */}
          <h2 className='text-2xl font-semibold'>Politiche e condizioni della prenotazione</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

          {/* CONTENT */}
          <div>
            <h4 className='text-lg font-semibold'>Politica di cancellazione</h4>
            <span className='block mt-3 text-neutral-500 dark:text-neutral-400'>
              Refund 50% of the booking value when customers cancel the room
              within 48 hours after successful booking and 14 days before the
              check-in time. <br />
              Then, cancel the room 14 days before the check-in time, get a 50%
              refund of the total amount paid (minus the service fee).
            </span>
          </div>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

          {/* CONTENT */}
          <div>
            <h4 className='text-lg font-semibold'>Check-in e check-out</h4>
            <div className='mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base'>
              <div className='flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg'>
                <span>Check-in</span>
                <span>{listing?.property?.check_in}</span>
              </div>
              <div className='flex space-x-10 justify-between p-3'>
                <span>Check-out</span>
                <span>{listing?.property?.check_out} </span>
              </div>
            </div>
          </div>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

          {/* CONTENT */}
          <div>
            <h4 className='text-lg font-semibold'>Note speciali</h4>
            <div className='prose sm:prose'>
              <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2'>
                <li>
                  Ban and I will work together to keep the landscape and
                  environment green and clean by not littering, not using
                  stimulants and respecting people around.
                </li>
                <li>Do not sing karaoke past 11:30</li>
              </ul>
            </div>
          </div>
          <div>
            <h4 className='text-lg font-semibold'>Cusr</h4>
            <div className='prose sm:prose'>
              {listing?.property?.cusr}
            </div>
          </div>
        </div>

      </div>
    );
  };
  const Hero = () => {
    const containerStyle = {
      backgroundImage: `url(${listing.property?.cover_url || listing.property?.place_cover})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      display: 'flex',
    };
    const { width, height } = useWindowSize();

    const renderSection5 = () => {
      return (
        <div className="md:w-full lg:w-2/3 px-6 py-8 flex flex-col space-y-3 bg-white rounded-lg">
          {/* HEADING */}
          <h2 className='text-2xl font-semibold hidden sm:block'>Informazioni Host</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>

          {/* host */}
          <div className='flex items-center space-x-4'>
            <Avatar
              imgUrl={listing.host?.avatar}
              hasChecked
              hasCheckedClass='w-4 h-4 -top-0.5 right-0.5'
              sizeClass='h-14 w-14'
              radius='rounded-full'
            />
            <div>
              <a className='block text-xl font-medium' href='##'>
                {listing.host?.name}
              </a>
              <div className='mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400'>
                <StartRating reviewCount={listing.host?.reviews_count} point={listing.host?.rating.toFixed(1)} />
                <span className='mx-2'>·</span>
              </div>
            </div>
          </div>

          {/* desc */}
          <span className='block text-neutral-6000 dark:text-neutral-300 text-sm	'>
            {listing.host?.description}
          </span>

          {/* info */}
          <div className='block text-neutral-500 dark:text-neutral-400 space-y-2.5'>
            <div className='flex items-center space-x-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'
                />
              </svg>
              <span className="text-sm"	>host TRIBYOU da {moment(listing.host?.created_at).format("DD MMM YYYY")}</span>
            </div>
            <div className='flex items-center space-x-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z'
                />
              </svg>
              <span className="text-sm	">Tasso di risposta - 100%</span>
            </div>
            <div className='flex items-center space-x-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z'
                />
              </svg>

              <span className="text-sm">Risponde velocemente - in poche ore</span>
            </div>
          </div>

          {/* == */}
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
          <div>
            <ButtonSecondary href={"/profile/" + listing.host?.host_uuid}>Vai al profilo</ButtonSecondary>
          </div>
        </div >
      );
    };

    return (
      <div className="hero-container relative h-96 md:h-[46rem] md:items-center md:justify-center items-center justify-start pl-5 md:pl-0" style={containerStyle}>
        <div className="absolute w-full h-full top-0 right-0 bg-[rgb(0,0,0,0.2)] z-[1]"></div>
        <section id="hero" className="z-[2]">
          <div className="container sm:space-x-3  lg:space-x-0 justify-center  xl:flex-row flex flex-col items-center md:pl-6 mx-auto space-y-12 md:flex-row">
            <div className="md:w-full lg:w-1/2 text-white mt-3 lg:mt-1 space-y-5">
              <div className="w-full flex flex-row">
                <div className="w-full sm:w-1/2">
                </div>

                <div className='text-white flex items-center  justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300'>
                  {/*
                               <div className='flex items-center space-x-1'>
                    <i className='las la-upload text-xl '></i>
                    <span className=' '>
                      <span className='font-normal text-xs'>Condividi</span>
                    </span>
                  </div>
                  <div className='flex items-center space-x-1'>
                    <i className='las la-heart text-xl'></i>
                    <span className=' '>
                      <span className='text-xs'>Salva</span>
                    </span>
                  </div>  
               */}
                </div>
              </div>
              <Badge className="!text-[0.8rem] !p-2" name={listing?.property?.mood} color={returnMoodColor(listing?.property?.mood)} />
              <h1 className="text-4xl sm:text-4xl md:text-7xl lg:w-auto font-semibold">
                {listing.property?.name}
              </h1>
              <hr className='bg-white mb-[2rem] md:mb-0' />

              <div className='flex w-[90%] items-start md:items-center space-y-3 md:space-y-0 flex-col md:flex-row  flex-wrap justify-between xl:justify-start space-x-0 xl:space-x-12 text-sm text-white dark:text-neutral-300'>
                <div className='flex items-center space-x-3 '>
                  <i className='las la-user text-xl'></i>
                  <span className=' font-normal text-md'>{listing.property?.guests_included} Ospiti</span>
                </div>
                <div className='flex items-center space-x-3'>
                  <i className='las la-bed text-xl'></i>
                  <span className='font-normal text-md'>{listing.property?.beds} camere da letto</span>
                </div>
                <div className='flex items-center space-x-3'>
                  <i className='las la-bath text-xl'></i>
                  <span className='font-normal text-md'>{listing.property?.bathrooms} bagni</span>
                </div>
                <div className='flex items-center space-x-3'>
                  <i className='las la-door-open text-xl'></i>
                  <span className='font-normal text-md'>{listing.property?.bedrooms} camere da letto</span>
                </div>
              </div>
            </div>
            {
              width >= 768 &&
              <div className="w-full xl:w-1/2 flex flex-col items-center">
                {renderSection5()}
              </div>
            }
          </div>
        </section>
      </div>

    );
  };

  const renderStatsSection = () => {
    const worksData = {
      labels: propertyStats?.works?.labels,
      datasets: [
        {
          data: propertyStats?.works?.counts,
          backgroundColor: [
            'rgba(247, 60, 105,0.9)',
            'rgba(54, 162, 235,0.9)',
            'rgba(255, 206, 86,0.9)',
            'rgba(75, 192, 192,0.9)',
            'rgba(153, 102, 255,0.9)',
            'rgba(255, 159, 64,0.9)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    const agesData = {
      labels: propertyStats?.ages?.labels,
      datasets: [
        {
          label: "Anni di nascita",
          data: propertyStats?.ages?.counts,
          backgroundColor: [
            'rgba(247, 60, 105,0.9)',
            'rgba(54, 162, 235,0.9)',
            'rgba(255, 206, 86,0.9)',
            'rgba(75, 192, 192,0.9)',
            'rgba(153, 102, 255,0.9)',
            'rgba(255, 159, 64,0.9)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    const citiesData = {
      labels: propertyStats?.cities?.labels,
      datasets: [
        {
          data: propertyStats?.cities?.counts,
          backgroundColor: [
            'rgba(247, 60, 105,0.9)',
            'rgba(54, 162, 235,0.9)',
            'rgba(255, 206, 86,0.9)',
            'rgba(75, 192, 192,0.9)',
            'rgba(153, 102, 255,0.9)',
            'rgba(255, 159, 64,0.9)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    if (isLoadingStats) {
      return (
        <div className="w-full md:w-1/3 lg:w-1/4 p-2">
          <Skeleton className="w-full h-[20rem]" />
        </div>
      )
    }
    if (propertyStats.works.labels.length && propertyStats.ages.labels.length && propertyStats.cities.labels.length) {
      return (
        <div className='listingSection__wrap'>
          <h2 className='text-2xl font-semibold'>La tribù:</h2>
          <p className='text-neutral-500 dark:text-neutral-400 !mt-2'>
            Scopri chi sono i viaggiatori che hanno scelto questo posto e quali sono le loro caratteristiche
          </p>
          <div className="flex flex-row flex-wrap !mt-10 gap-6 md:gap-0">
            <div className="flex flex-col md:basis-[10%] basis-full">
              <div className="w-[13rem] mb-[1rem]">
                <Badge className="!text-[0.8rem] !p-2" name="Settore lavorativo" color="red" />
              </div>
              <Doughnut data={worksData} />
            </div>
            {/* separator */}
            <div className="md:w-[1px] bg-gray-300 md:mx-[1rem] w-full h-[1px] md:h-auto"></div>
            {/* right */}
            <div className="md:basis-[38%] flex flex-col basis-full">
              <div className="w-[13rem] mb-[1rem]">
                <Badge className="!text-[0.8rem] !p-2" name="Età media" color="green" />
              </div>
              <Bar data={agesData} />
            </div>
            {/* separator */}
            <div className="md:w-[1px] bg-gray-300 md:mx-[1rem] w-full h-[1px] md:h-auto"></div>
            {/* right */}
            <div className="flex flex-col md:basis-[10%] basis-full">
              <div className="w-[13rem] mb-[1rem]">
                <Badge className="!text-[0.8rem] !p-2" name="Provenienza" color="blue" />
              </div>
              <Doughnut data={citiesData} />
            </div>
          </div>
        </div>
      )
    }
  }



  const Info = () => {
    if (!showLoginModal) {
      return (
        <div className={`listingSection__wrap mt-0 mb-[3rem] top-0 bg-white ${isOpenModalAmenities ? "z-0" : "z-[90]"} rounded-t-none `}>
          <div className="full flex flex-col md:flex-row space-x-4 lg:space-x-0 pr-4 md:pr-0 items-center justify-between w-[90%] self-center">
            <div className="w-auto flex flex-col my-3 md:my-0 pl-4">
              <h1 className='text-2xl sm:text-3xl md:text-4xl font-semibold'>
                €{listing.property.weekday_price ?
                  Number(listing.property.weekday_price) + Number(listing.property.weekday_price * 0.15)
                    + Number(listing.property.weekday_price * 0.15) * 0.22 : 'N/A'} 
                <span className="text-xs sm:text-sm md:text-xs">/notte</span>
              </h1>
              {listing.property.cleaning_fee > 0 && (
                <div className='text-sm text-neutral-500 dark:text-neutral-400'>
                  + €{listing.property.cleaning_fee} spese di pulizia per notte
                </div>
              )}
              <div className='flex items-center'>
                <i className='las la-star text-xl sm:text-2xl text-rose-500'></i>
                <span className=''>
                  <span className='font-normal text-xs sm:text-sm md:text-xs'>{listing.property.rating} ({listing.property.reviews} recensioni)</span>
                </span>
              </div>
            </div>

            <div className="w-auto text-neutral-500 dark:text-neutral-400">
              <div className="flex gap-4 flex-row items-center border border-slate-200 rounded-xl px-10 py-2">
                {
                  <InputBar
                    onMonthChange={onMonthChange}
                    startDate={startDate}
                    endDate={endDate}
                    onChangeDate={onChangeDate}
                    onChangeGuests={onChangeGuests}
                    guests={guests}
                    maxGuests={listing.property?.guests_included}
                    showDatePicker={showDatePicker}
                    setShowDatePicker={setShowDatePicker}
                    rangeAvailability={rangeAvailability}
                  />
                }
              </div>
            </div>

            <div className="w-[15rem]  md:text-right mt-4 md:mt-0">
              <ButtonPrimary
                disabled={listing.property?.stopped}
                onClick={() => !showDatePicker ? handleStartBooking(false) : toast.error("Seleziona le date per continuare")}
                loading={isCheckingPropertyAvailability || loading}
                className="w-11/12"
              >
                Aggiungi al mio viaggio
              </ButtonPrimary>
            </div>
          </div>
        </div>
      )
    }
  }

  const Carriage = () => {
    return (
      <div className="flex flex-col md:flex-row flex-wrap">
        {
          listing.property?.media.map((item: string, index: number) => (
            <div key={index} className="md:w-1/2 lg:w-[20rem] space-y-1 w-full md:space-y-2 md:pr-4 mt-20  md:mt-4 lg:mt-0 rounded-md">
              <img src={item} alt="car1" className="w-full rounded-md" />
            </div>
          ))
        }
      </div>
    )
  }

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const endDate = moment(start).add(range - 1, "days").toDate();

    const startMonth = moment(start).format("MM");
    const endMonth = moment(endDate).format("MM");

    const startDay = moment(start).format("DD");
    const endDay = moment(endDate).format("DD");

    let isDateBooked = false;

    // Controllo se una data selezionata è già prenotata
    for (let month = parseInt(startMonth, 10); month <= parseInt(endMonth, 10); month++) {
      const monthStr = month < 10 ? `0${month}` : `${month}`;
      const daysInMonth = rangeAvailability[monthStr];
      if (daysInMonth) {
        for (let day = parseInt(startDay, 10); day <= parseInt(endDay, 10); day++) {
          const dayStr = day < 10 ? `0${day}` : `${day}`;
          if (daysInMonth.includes(dayStr)) {
            isDateBooked = true;
            break;
          }
        }
      }
      if (isDateBooked) break;
    }

    if (isDateBooked) {
      toast.error("Non puoi selezionare queste date perchè sono già prenotate");
    } else {
      setStartDate(start as Date);
      if (range) {
        setEndDate(endDate);
      } else {
        setEndDate(end as Date);
      }
    }
  };

  if (isLoading || !listing) {
    return (
      <main className="w-full overflow-hidden">
        <div className="w-full h-96">
          <Skeleton height={"100%"} width={"100%"} />
        </div>
        <div className="mt-5 pl-5">
          <Skeleton height={50} width={"80%"} />
        </div>
        <div className="mt-5 pl-5">
          <Skeleton height={200} width={"80%"} />
        </div>
      </main>
    )
  }

  if (listing?.property?.is_deleted) {
    return (
      <div className="container mt-11 mb-24 lg:mb-32">
        <div className="flex flex-col items-center justify-center space-y-8">
          <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100">
            Questo annuncio non è più disponibile
          </h2>
          <ButtonPrimary href="/listing-stay">Torna alla ricerca</ButtonPrimary>
        </div>
      </div>
    );
  }

  return (
    <>
      <main className='w-full'>
        <div className='w-full'>
          <Hero />
          {
            width >= 768 &&
            <Info />
          }
          {
            width <= 768 &&
            renderSectionGallery()
          }

          <div className='w-5/6  mx-auto space-y-8 lg:space-y-10 lg:pr-10'>
            {
              width >= 768 &&
              <Carriage />
            }

            {renderSection2()}
            {renderSectionExperiences()}

            {renderSection3()}
            {renderStatsSection()}
            {renderSection6()}
            {renderSection7()}
            {renderSection8()}
          </div>
        </div>
      </main >
      <ModalLogin
        showModal={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        onLoginSuccess={() => handleStartBooking(true)}
      />
      <Transition
        show={showFooterSticky}
        as={Fragment}
      >
        <Transition.Child>
          <MobileFooterSticky
            isAvailable={true}
            price={listing.property.weekday_price}
            rating={listing.property.rating}
            reviews={listing.property.reviews}
            showDateModal
            startDate={startDate}
            endDate={endDate}
            handleStartBooking={() => handleStartBooking(false)}
            onChangeDate={onChangeDate}
            rangeAvailability={rangeAvailability}
          />
        </Transition.Child>
      </Transition>


    </>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}

