import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

//https://tribyou-backend.vercel.app/api/
//http://192.168.178.120:3001/api/
// const BASE_URL = 'http://192.168.178.120:3001/api/'
export const BASE_URL = "https://tribyou-api.it/api/"
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    if (endpoint?.endsWith("/upload/cover")) {
      headers.set("Content-Type", "multipart/form-data");
    }
    if (!endpoint?.endsWith("/login-host") || !endpoint?.endsWith("/become-host") || !endpoint?.endsWith("/login-client") || !endpoint?.endsWith("/sign-up-client")) {
      let tokenHost = getState().hostAuth.token;
      let tokenClient = getState().persistedReducer.authclient.token;

      if (tokenHost || tokenClient) {
        headers.set("Authorization", `${tokenHost || tokenClient}`);
      } else {
        tokenHost = localStorage.getItem('host_token')
        tokenClient = localStorage.getItem('client_token')

        if (tokenClient || tokenHost) {
          headers.set("Authorization", `${tokenHost || tokenClient}`);
        }
      }
    }
    return headers;
  }
});

export const apiSlice = createApi({
  baseQuery: baseQuery,
  tagTypes: ["Host", "Property", "EditingProperty", 'Client', 'Booking', 'ExperiencesList', 'Chats', 'Messages', 'SearchResults', 'Borgo'],
  endpoints: builder => ({})
});

