import { Popover, Transition } from '@headlessui/react';
import { ChevronRightIcon, GlobeAsiaAustraliaIcon, TrashIcon } from '@heroicons/react/24/outline';
import { selectBookings, setBooking, setBookings } from 'features/bookings/bookingsSlice';
import moment from 'moment';
import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useWindowScroll } from 'react-use';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

interface MyTripProps {
  // Define the props for your component here
}

const MyTrip: React.FC<MyTripProps> = (props) => {
  // Implement your component logic here
  const bookings = useSelector(selectBookings);
  const { x, y } = useWindowScroll();
  const popoverButton = useRef<HTMLButtonElement>(null);
  const [show, setShow] = React.useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleRemove = (property_uuid: string) => {
    // remove from bookings list by property_uuid
    const newBookings = bookings.filter((booking: any) => booking?.property?.property_uuid !== property_uuid);
    // update bookings list
    dispatch(setBookings(newBookings));
  }

  useEffect(() => {
    if (y > 0) {
      if (show) {
        popoverButton.current?.click();
      }
    }
  }, [y])


  return (
    // JSX code for your component's UI goes here
    <Popover>
      {({ open, close }) => (
        <>
          <Popover.Button ref={popoverButton} onClick={() => setShow(!open)} className='flex flex-row items-center gap-2 bg-neutral-100 dark:bg-neutral-800 dark:text-neutral-200 py-2 px-4 rounded-full cursor-pointer hover:opacity-80'>
            <GlobeAsiaAustraliaIcon className="h-6 w-6" />
            <span>
              Il mio viaggio
            </span>

            {bookings?.length > 0 && (
              <span className='text-white text-xs bg-red-400 rounded-full px-3 py-1'>
                {bookings.length}
              </span>
            )}
          </Popover.Button>
          { }
          <Transition
            as={Fragment}
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Popover.Panel className={`fixed bg-white h-[${bookings.length > 0 ? "20rem" : "10rem"}] w-screen left-0 z-[99] mt-5 rounded-b-lg shadow-lg px-10 py-4`}>
              {
                bookings && bookings.length === 0 ?
                  <div className='flex flex-col items-center justify-center gap-4'>
                    <span>
                      Non hai ancora aggiunto nessuna struttura
                    </span>
                    <ButtonPrimary
                      onClick={() => window.location.href = '/#/1/search'}
                    >
                      Esplora
                    </ButtonPrimary>
                  </div>
                  : <>
                    <h3 className="text-lg font-bold">Le mie prenotazioni:</h3>
                    <div className="mt-3 flex flex-row gap-10">
                      {bookings?.map((booking: any) => (
                        <div
                          key={booking?.property?.property_uuid}
                          className='w-[14rem] h-[15rem] flex flex-col  rounded-lg cursor-pointer'
                        >
                          <div
                            className="bg-cover bg-center h-32 w-full rounded-t-lg"
                            style={{ backgroundImage: `url(${booking?.property?.cover})` }}
                            onClick={() => navigate(`/listing-stay-detail/${booking?.property?.property_uuid}`)}
                          >
                          </div>
                          <div className='mt-2 flex-col flex p-3'>
                            <h4
                              className='text-lg font-medium'
                              onClick={() => navigate(`/listing-stay-detail/${booking?.property?.property_uuid}`)}
                            >
                              {booking?.property?.name}
                            </h4>
                            <span className='text-sm'>
                              Dal {moment(booking?.startDate).format("DD-MM-YYYY")} al {moment(booking?.endDate).format("DD-MM-YYYY")}
                            </span>
                            <span className='text-sm font-bold mt-3 flex flex-row items-center justify-between'>
                              {booking?.price} €
                              <span
                                className='text-sm flex-row flex items-center text-red-500 gap-2 hover:opacity-80'
                                onClick={() => handleRemove(booking?.property?.property_uuid)}
                              >
                                <TrashIcon className="h-5 w-5" />
                                <span className='mt-1'>
                                  Rimuovi
                                </span>
                              </span>
                            </span>
                          </div>

                        </div>
                      ))}
                      <div className='max-w-[14rem] flex items-center justify-center ml-10'>
                        {
                          bookings.length ?
                            <ButtonPrimary
                              onClick={() => navigate("/client/booking/calendar")}
                            >
                              Vai al checkout
                              <ChevronRightIcon className="h-5 w-5" />
                            </ButtonPrimary>
                            : null
                        }
                      </div>
                    </div>
                  </>
              }

            </Popover.Panel>
          </Transition>
        </>
      )
      }

    </Popover >
  );
};

export default MyTrip;
