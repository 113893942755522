import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import f1 from "../../images/f1.png"
import f2 from "../../images/f2.png"

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "Azienda",
    menus: [
      { href: "/about", label: "Chi siamo" },
      { href: "/contact", label: "Contatti" },
      { href: "/become-host", label: "Diventa un host" },
    ],
  },
  {
    id: "2",
    title: "Con il supporto economico di",
    menus: [
      { href: "#", image: f1 },
      { href: "#", image: f2 },
    ],
  },
  {
    id: "4",
    title: "Supporto & Press",
    menus: [
      { href: "#", label: "help@tribyou.life" },
      { href: "#", label: "press@tribyou.life" },
    ],
  },
  {
    id: "6",
    title: "Altri dati",
    menus: [
      { href: "#", label: "PEC: hqvillagesbsrl@pec.it" },
      { href: "#", label: "Codice univoco: M5UXCR1" },
    ],
  },
  {
    id: "7",
    title: "Community sicura",
    menus: [
      { 
        href: "https://www.coyzy.com",
        label: "",
        image: "https://www.coyzy.com/static/logo-startup-small.bc9b235e389a.png",
        isExternal: true,
        subText: "Scopri come rendiamo più sicura la Community"
      },
    ],
  },
];

const Footer: React.FC = () => {
  const renderMenuItem = (menu: CustomLink) => {
    if (menu.isExternal) {
      return (
        <div className="flex flex-col items-start gap-2">
          <a
            href={menu.href}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
          >
            {menu.image && <img src={menu.image} alt="Coyzy" className="h-6" />}
          </a>
          {menu.subText && (
            <a
              href={menu.href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
            >
              {menu.subText}
            </a>
          )}
        </div>
      );
    }
    
    if (menu.image) {
      return (
        <a href={menu.href}>
          <img src={menu.image} alt="" />
        </a>
      );
    }

    return (
      <a
        className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
        href={menu.href}
      >
        {menu.label}
      </a>
    );
  };

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {renderMenuItem(item)}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-6 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
