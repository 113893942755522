import React, { FC, useEffect, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link, useNavigate } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import Skeleton from "react-loading-skeleton";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import moment from "moment";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AddToCalendarButton } from 'add-to-calendar-button-react';

export interface StayCardProps {
  className?: string;
  data?: any;
  size?: "default" | "small";
  customData?: any;
  href?: string;
  loading?: boolean;
  showRefund?: boolean;
  onRefundClick?: () => void;
  onReviewClick?: () => void;
  showReview?: boolean;
  navigationIsNotAlowed?: boolean;
  myReservations?: boolean;
  bookingPrice?: number;
  check_in?: string;
  check_out?: string;
  showCanceled?: boolean;
  reviews_count?: number;
  rating?: number;
  comment?: string;
  score?: number;
  showEdit?: boolean;
  showAddToCalendar?: boolean;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const returnMoodColor = (mood: string) => {
  switch (mood) {
    case "authentic":
      return "green";
    case "business":
      return "blue";
    case "comfort":
      return "yellow";
    default:
      return "red";
  }
}
const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
  navigationIsNotAlowed,
  href,
  showRefund,
  showReview,
  onRefundClick,
  onReviewClick,
  loading,
  showCanceled,
  comment,
  rating,
  showEdit,
  score,
  myReservations,
  bookingPrice,
  check_in,
  check_out,
  showAddToCalendar
}) => {
  const navigate = useNavigate()


  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${data?.property_uuid}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={data?.media && data?.media.length ? data?.media : [data?.cover]}
          href={href}
        />
        {/*saleOff && <SaleOffBadge className="absolute left-3 top-3" />         <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" />*/}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {data?.property_type === "apartment" ? "Appartmento" : data?.property_type} · {data?.bedrooms} camere da letto
          </span>
          <div className="flex items-center space-x-2">
            {data?.mood && <Badge name={data.mood} color={returnMoodColor(data?.mood)} />}
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                }`}
            >
              <span className="line-clamp-1">{data?.name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{data?.address}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          {
            !myReservations &&
            <span className="text-base font-semibold">
              da {((Number(data?.weekday_price) || 0) + ((Number(data?.weekday_price) || 0) * 0.15) + ((Number(data?.weekday_price) || 0) * 0.15 * 0.22)).toFixed(2)}
              {` `}
              {size === "default" && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  /notte
                </span>
              )}
            </span>
          }
          {
            myReservations &&
            <div className="text-base font-semibold flex flex-col">
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                <b>Pagato</b> {bookingPrice}€
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                Dal {moment(check_in).format("DD MMM")} al {moment(check_out).format("DD MMM")}
              </span>
            </div>
          }
          {!!data?.rating && (
            <StartRating reviewCount={data?.reviews || data?.reviews_count} point={data?.rating || data?.score} />
          )}
        </div>
        {
          showRefund && (
            <div className="flex items-center justify-between">
              <ButtonSecondary
                onClick={onRefundClick}
              >
                Annulla prenotazione
              </ButtonSecondary>
              {showAddToCalendar && (
                <div className="absolute z-[99] right-2">
                  <AddToCalendarButton
                    name={data?.name}
                    startDate={moment(check_in).format("YYYY-MM-DD")}
                    options={['Apple', 'Google', 'MicrosoftTeams']}
                    timeZone="Europe/Rome"
                    buttonStyle="round"
                    hideTextLabelButton
                    size="5"
                    listStyle="overlay"
                    language="it"
                    buttonsList
                  ></AddToCalendarButton>
                </div>
              )}
            </div>
          )
        }

        {
          showEdit && (
            <div className="flex items-center justify-between">
              <ButtonPrimary
                onClick={() => navigate("/host/edit-property/" + data?.property_uuid)}
              >
                Modifica
              </ButtonPrimary>
            </div>
          )
        }
        {
          showReview && (
            score ?
              <div className="flex items-center justify-between">
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  La tua recensione:
                </span>
                <StartRating reviewCount={1} point={score} />
              </div>
              :
              <div className="flex items-center justify-between">
                <ButtonPrimary
                  onClick={onReviewClick}
                >
                  Scrivi una recensione
                </ButtonPrimary>
              </div>
          )
        }
        {
          showCanceled && (
            <div className="flex items-center justify-between">
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                Prenotazione annullata
              </span>
            </div>
          )
        }
      </div >
    );
  };

  return (
    <div
      className={`nc-StayCard cursor-pointer group relative bg-white dark:bg-neutral-900 ${!loading && "border border-neutral-100 dark:border-neutral-800"} rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {
        !loading && data?.property_uuid ?
          <>
            {renderSliderGallery()}
            <div onClick={() => !navigationIsNotAlowed && !showRefund && !showReview ? navigate(href ? href : "/listing-stay-detail/" + data?.property_uuid) : null}>
              {renderContent()}
            </div>
          </>
          :
          <Skeleton height={size === "default" ? 300 : 200} />
      }
    </div>
  );
};

export default StayCard;
