
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";
import { useLoginHostMutation } from "features/hostAuth/authHostApiSlice";
import { selectCurrentHost, selectCurrentToken, setCredentials } from "features/hostAuth/authHostSlice";
import { useDispatch, useSelector } from "react-redux";
import InputPsw from "shared/InputPsw/InputPsw";

export interface PageLoginHostProps {
  className?: string;
}

const PageLoginHost: FC<PageLoginHostProps> = ({ className = "" }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const token = useSelector(selectCurrentToken);

  const [showOtp, setShowOtp] = useState(false);
  const [loginHost, { isLoading }] = useLoginHostMutation()

  useEffect(() => {
    if (token) {
      navigate("/host/")
    }

  }, [token])

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      const loginReponse = await loginHost({ email, password, otp_code: otp }).unwrap()
      if (!loginReponse.error) {
        dispatch(setCredentials({ ...loginReponse }));
        localStorage.setItem('host_token', loginReponse.token)
      }
    } catch (error) {
      setError("Email o password sbagliata")
    }
  }

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    if (!email || !password) {
      setError("Inserisci email e password")
      return
    }
    try {
      const loginReponse = await loginHost({ email, password }).unwrap()
      if (!loginReponse.error) {
        dispatch(setCredentials({ ...loginReponse }));
        localStorage.setItem('host_token', loginReponse.token)
      }
    }
    catch (error: any) {
      if (error.status === 402) {
        setShowOtp(true);
      } else {
        setError("Dati di accesso non validi")
      }
    }
  }


  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign in || TRIBYOU</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <div className="max-w-md mx-auto space-y-6 ">
          {/* FORM */}

          {!showOtp ?

            <>
              <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Accedi come host
              </h2>
              <form className="grid grid-cols-1 gap-6" onSubmit={onFormSubmit} method="post">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Indirzzo email
                  </span>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                  </span>
                  <InputPsw
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="mt-1"
                  />
                </label>
                <span className="block text-left text-neutral-700 dark:text-neutral-300 cursor-pointer hover:text-primary-6000">
                  <Link to="/reset-password-host">Ho dimenticato la password</Link>
                </span>
                {error && <span className="text-red-500">{error}</span>}
                <ButtonPrimary type="submit" loading={isLoading}>Accedi</ButtonPrimary>
              </form>

              {/* ==== */}
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Sei nuovo? {` `}
                <Link to="/become-host">Registrati</Link>
              </span>
            </>
            :
            <>
              <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Verifica la tua email
              </h2>
              <p className="mt-2 text-neutral-800">
                Ti abbiamo inviato un codice di verifica all'indirizzo email
                <span className="font-semibold"> {email}</span>
              </p>
              <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin} method="post">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Codice a 6 cifre
                  </span>
                  <Input
                    onChange={(e) => setOtp(e.target.value)}
                    type="text"
                    placeholder="123456"
                    className="mt-1"
                  />
                </label>
                {error && <span className="text-red-500">{error}</span>}
                <ButtonPrimary loading={isLoading} type="submit">Verifica</ButtonPrimary>
              </form>

              {/* ==== */}
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Non hai ricevuto il codice? {` `}
                <a className="text-red-500">Invia di nuovo</a>
              </span>
            </>
          }

        </div>
      </div>
    </div>
  );
};

export default PageLoginHost;
