import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import React, { useRef, useEffect } from 'react';

interface ChatBodyInputProps {
  // Define props here
  sendMessage?: (e: any) => Promise<any>,
  sendingMessage?: Boolean
}
function contieneNumeroTelefonoOEmail(testo:string) {
  // Espressione regolare per controllare se la stringa contiene un numero di telefono
  const numeroTelefonoRegex = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/;
  // Espressione regolare per controllare se la stringa contiene un'email
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
  
  // Controlla se la stringa contiene un numero di telefono o un'email
  return numeroTelefonoRegex.test(testo) || emailRegex.test(testo);
}
const ChatBodyInput: React.FC<ChatBodyInputProps> = (props) => {
  // Component logic here
  const chatForm = useRef<HTMLFormElement>(null);
  const chatMessage = useRef<HTMLInputElement>(null);

  // useEffect( () => {
  //   if( !props.sendingMessage ){
  //     if( chatMessage.current !== null ){
  //       chatMessage.current.focus();
  //     }
  //   }
  // }, [props.sendingMessage] )

  const handleEntericonPress = ( event: any ) => {
    event.preventDefault();
    chatMessage?.current?.focus();
    sendMessage();
  }

  const HandleEnterKeyPress = ( e: any ) => {
    if( e.key === "Enter" ){
      sendMessage();
      e.preventDefault();
    }

  }

  // const handleMessage
  const sendMessage = () => {
    if( !props.sendingMessage ){
      chatMessage?.current?.focus(); // Keep the focus in message field
      if( chatMessage.current !== null ){
        let message = chatMessage.current.value;
        // if message contains phone number or email don't send

        if( contieneNumeroTelefonoOEmail(message) ){
          alert( "Non è possibile inviare numeri di telefono o email" );
          return;
        }
        message = message.trim();
        if( message ){
          if (props.sendMessage) {
            props.sendMessage( message ).then( () => {
              if( chatMessage.current ){
                chatMessage.current.value = '';
              }
            });
          }
        }
      }
    }
  }

  return (
    <div className={`flex w-full h-[4rem] absolute bottom-[5rem] md:bottom-0 left-0 bg-white items-center  ${ props.sendingMessage ? 'hover:cursor-wait' : '' }`}>
      <form
        onSubmit={ (event) => { event.preventDefault(); }}
        className={`w-full h-full rounded-md bg-neutral-100 p-3 relative items-center ${ props.sendingMessage ? 'disabled hover:cursor-wait' : '' }`}
        // ref={chatForm}
        >
        <input
          type='text'
          name='message'
          className={`outline-none bg-transparent w-full h-full border-none pr-8 ${ props.sendingMessage ? 'hover:cursor-wait' : '' }`}
          placeholder='Scrivi un messaggio...'
          // disabled={ props.sendingMessage }
          onKeyUp={HandleEnterKeyPress}
          ref={chatMessage}
        />
        <PaperAirplaneIcon
          className={`w-6 h-6 text-primary-500 absolute right-5 top-[0.85rem] mt-[0.35rem] absolute ${ props.sendingMessage ? 'hover:cursor-wait' : 'hover:cursor-pointer' }`} onClick={handleEntericonPress} />
      </form>
    </div>
  );
};

export default ChatBodyInput;
