import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";
import SectionGridFeatureProperty from "containers/PageHome/SectionGridFeatureProperty";
import { useGetAllMyPropertiesQuery, useLazyGetAllMyExperiencesQuery } from "features/host/hostApiSlice";
import Skeleton from "react-loading-skeleton";
import StayCard from "components/StayCard/StayCard";
import { StayDataType } from "data/types";
import Heading from "components/Heading/Heading";
import HeaderFilter from "containers/PageHome/HeaderFilter";
import ExperienceCard from "components/ExperienceCard/ExperienceCard";

const PageMyListing: React.FC = () => {
  const { data: properties, isLoading, isError } = useGetAllMyPropertiesQuery({});
  const [getExperiences, { data: experiences, isLoading: isLoadingExperiences }] = useLazyGetAllMyExperiencesQuery({});

  const [activeTab, setActiveTab] = React.useState<string>("Alloggi");

  const handleGetExperiences = async () => {
    try {
      await getExperiences({}).unwrap();
    } catch (err) {
      console.log(err);
    }
  }

  const handleActiveTabChange = () => {
    if (activeTab === "Esperienze") {
      handleGetExperiences();
    }
  }

  const renderCard = (stay: any) => {
    if (activeTab === "Alloggi") {
      return <StayCard showEdit  href={"/host/edit-property/" + stay.property_uuid} key={stay.property_uuid} data={stay} />;
    }
    else {
      return <ExperienceCard showEdit  href={"/host/edit-experience/" + stay.experience_uuid} key={stay.experience_uuid} data={stay} />;
    }
  };

  const handleChangeTab = () => {
    if (activeTab === "Alloggi") {
      if (properties) {
        return properties.map((item: any) => renderCard(item))
      }
    }
    if (activeTab === "Esperienze") {
      if (experiences) {
        return experiences.map((item: any) => renderCard(item))
      }
    }
    return (
      <div className="col-span-full flex flex-col items-center justify-center py-20">
        <h3 className="text-2xl font-semibold mb-4">Coming Soon</h3>
        <p className="text-neutral-500">Questa funzionalità sarà disponibile prossimamente</p>
      </div>
    )
  };

  useEffect(() => {
    handleActiveTabChange();
  }, [activeTab])

  const tabs = [
    "Alloggi", 
    "Esperienze", 
    "Servizi", 
    "Eventi", 
    "Convenzione", 
    "Offerta Straordinaria"
  ]

  return (
    <div className="nc-annunci">
      <Helmet>
        <title>I miei annunci | TRIBYOU</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-8 flex flex-col">
        <HeaderFilter
          tabActive={activeTab}
          tabs={tabs}
          heading={"I miei annunci"}
          subHeading={"I tuoi annunci attivi su TRIBYOU"}
          onClickTab={(e) => setActiveTab(e)}
        />
        <div className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-3`}>
          {isLoading || isLoadingExperiences ? (
            <>
              <Skeleton className="w-full h-[10rem] md:h-[10rem]" />
              <Skeleton className="w-full h-[10rem] md:h-[10rem]" />
              <Skeleton className="w-full h-[10rem] md:h-[10rem]" />
            </>
          ) : (
            handleChangeTab()
          )}
        </div>
      </div>
    </div>
  )
}

export default PageMyListing;
