import React, { useEffect, useState } from 'react';
import { MapPinIcon } from '@heroicons/react/24/solid';

interface MarkerProps {
  lat: number;
  lng: number;
  className?: string;
  $hover?: boolean;
  isSelected?: boolean;
}

const Marker: React.FC<MarkerProps> = ({ className = "", $hover, isSelected }) => {
  const [zoom, setZoom] = useState<number>(18);

  useEffect(() => {
    const mapElement = document.querySelector('[class*="google-map"]');
    if (mapElement) {
      const observer = new MutationObserver(() => {
        const map = (window as any).google?.maps?.Map?.instance;
        if (map) {
          setZoom(map.getZoom());
          map.addListener('zoom_changed', () => {
            setZoom(map.getZoom());
          });
        }
      });

      observer.observe(mapElement, { 
        attributes: true,
        childList: true,
        subtree: true 
      });

      return () => observer.disconnect();
    }
  }, []);

  if (zoom < 13) {
    return (
      <div className={`${className} relative`}>
        <div className={`
          w-16 h-16 
          rounded-full 
          bg-primary-500/20
          border-2 border-primary-500
          animate-pulse
          ${$hover || isSelected ? 'scale-110' : 'scale-100'}
          transition-transform duration-200
        `}/>
      </div>
    );
  }

  return (
    <div className={`${className} relative group`}>
      <MapPinIcon className={`
        w-8 h-8 
        text-primary-500 
        ${$hover || isSelected ? 'scale-110' : 'scale-100'}
        transition-transform duration-200
      `} />
      {($hover || isSelected) && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 
                      bg-white px-2 py-1 rounded-lg shadow-lg text-sm whitespace-nowrap">
          Visualizza dettagli
        </div>
      )}
    </div>
  );
};

export default Marker; 