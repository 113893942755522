import React, { FC, useEffect, useState } from "react";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

export interface PageChooseListingTypeProps { }

const PageChooseListingType: FC<PageChooseListingTypeProps> = () => {
  const [listingType, setListingType] = useState<String>("");
  return (
    <CommonLayout
      index=""
      backtHref="/host/"
      nextHref={listingType === "experince" ? "/host/new-experience/add-listing-1" : "/host/new-property/add-listing-1"}
      canGoNext={listingType?.length > 0}
    >
      <>
        <h2 className="text-2xl font-semibold">Cosa vuoi caricare?</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Scegli il tipo di annuncio"
            desc=""
          >
            <Select
              defaultValue={""}
              onChange={(e) => setListingType(e.target.value)}
            >
              <option defaultChecked value={""}>Seleziona</option>
              <option value={"experince"}>Voglio caricare un'esperienza</option>
              <option value={"property"}>Voglio caricare un alloggio</option>
              <option value={""} disabled>Voglio caricare un servizio (Coming soon)</option>
              <option value={""} disabled>Voglio caricare un evento (Coming soon)</option>
              <option value={""} disabled>Voglio caricare una convenzione o offerta straordinaria (Coming soon)</option>
            </Select>
          </FormItem>
        </div>
      </>
    </CommonLayout >
  );
};

export default PageChooseListingType;
