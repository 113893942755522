import rightImg from "images/about-hero-right.png";
import React, { FC, useEffect, useState } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { useParams } from "react-router-dom";
import { useGetBorgoQuery, useGetBorgoSectionsQuery } from "features/borghi/borghiApiSlice";
import { useLazyFilterPropertiesQuery, useLazyGetByCityQuery } from "features/search/searchApiSlice";
import StayCard from "components/StayCard/StayCard";
import Heading from "shared/Heading/Heading";
import Skeleton from "react-loading-skeleton";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface PageBorgoProps {
  className?: string;
}

const PageBorgo: FC<PageBorgoProps> = ({ className = "" }) => {
  const [showAllAmenities, setShowAllAmenities] = useState(false);
  const { name } = useParams();
  const { data, isLoading } = useGetBorgoQuery(name);
  const { data: sections, isLoading: isLoadingSections } = useGetBorgoSectionsQuery(name);

  const [filterProperties, { data: properties, isLoading: isLoadingProperties }] = useLazyGetByCityQuery({});

  useEffect(() => {
    filterProperties({ city: name });
  }, []);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const renderCard = (stay: any) => {
    return <StayCard key={stay.property_uuid} data={stay} />;
  };

  const renderAmenities = () => {
    if (!data?.borgo?.amenities?.length) return null;

    const displayedAmenities = showAllAmenities 
      ? data.borgo.amenities 
      : data.borgo.amenities.slice(0, 5);

    return (
      <div className="container relative space-y-8 mb-24 lg:space-y-10">
        <div className="relative py-16">
          <h2 className="text-2xl font-semibold">Servizi disponibili nella zona</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mt-4"></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
            {displayedAmenities.map((amenity: { amenity: string }, index: number) => (
              <div 
                key={index}
                className="flex items-center p-4 bg-neutral-50 dark:bg-neutral-800 rounded-lg"
              >
                <span className="text-neutral-600 dark:text-neutral-300">
                  {amenity.amenity}
                </span>
              </div>
            ))}
          </div>
          {data.borgo.amenities.length > 5 && (
            <div className="flex justify-center mt-8">
              <ButtonSecondary 
                onClick={() => setShowAllAmenities(!showAllAmenities)}
              >
                {showAllAmenities ? "Mostra meno" : `Mostra tutti i ${data.borgo.amenities.length} servizi`}
              </ButtonSecondary>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <Skeleton height={500} />;
  }

  return (
    <div
      className={`nc-PageBorgo overflow-hidden relative ${className}`}
      data-nc-id="PageBorgo"
    >
      <Helmet>
        <title>{data?.borgo?.name || "Borgo"} || TRIBYOU</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={data?.borgo?.cover || ""}
          heading={data?.borgo?.name && capitalizeFirstLetter(data.borgo.name) || ""}
          btnText=""
          subHeading={data?.borgo?.short_description || ""}
          verified={data?.borgo?.verified}
        />
        {renderAmenities()}
        <div className="nc-SectionGridFeaturePlaces relative">
          <Heading
          desc={`A ${data?.borgo?.name} ci sono ${properties?.length || 0} strutture disponibili`}
          >
            {`Strutture a ${data?.borgo?.name}`}
          </Heading>
          <div
            className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
          >
            {
              properties && properties.length > 0 &&
              properties.map(renderCard)
            }
          </div>
          {/* 
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Scopri di più</ButtonPrimary>
      </div>
      */}
        </div>
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageBorgo_" />
        </div>

        <SectionStatistic
          borgo={data?.borgo || {}}
        />
        {
          // map sections except the first one
          sections && sections.sections.slice(1).map((section: any, index: number) => {
            return (
              <SectionFounder
                title={section.title}
                description={section.description}
                image={section.cover}
                key={section.section_uuid}
                index={index}
              />
            );
          })
        }
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageBorgo;
