import React, { FC, useEffect, useState, Fragment } from "react";
import CommonLayout from "./CommonLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectBookings, setBookings } from "features/bookings/bookingsSlice";
import CheckoutForm from "components/CheckoutForm/CheckoutForm";
import { useStartBookingMutation } from "features/bookings/bookingApiSlice";
import { selectEvents, setEvents } from "features/calendar/calendarSlice";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "shared/Checkbox/Checkbox";
import { Dialog, Transition } from "@headlessui/react";
import { useGetMeClientQuery, useUpdateClientSelfMutation } from "features/client/clientApiSlice";
import Input from "shared/Input/Input";
import { toast } from "react-toastify";
import { setLoggedClient } from "features/clientAuth/authClientSlice";

export interface PageAddListing1Props { }

const PaymentStep: FC<PageAddListing1Props> = () => {
  const [clientSecret, setClientSecret] = useState<string>("");
  const bookings = useSelector(selectBookings);
  const events = useSelector(selectEvents);
  const [requestInvoice, setRequestInvoice] = useState<boolean>(false);
  const [requestingInvoice, setRequestingInvoice] = useState<boolean>(true);
  const [showBillingForm, setShowBillingForm] = useState(false);
  
  const { data: client } = useGetMeClientQuery({});
  const [updateClientSelf] = useUpdateClientSelfMutation();
  const [clientUpdated, setClientUpdated] = useState<any>(client);

  const dispatch = useDispatch();

  const prevUrl = `/client/booking/summary`;

  const [startBooking, { isLoading: isLoadingBooking, isError, isSuccess, data, error }] = useStartBookingMutation({});

  const handleUpdateClient = (field: string, value: any) => {
    setClientUpdated({ ...clientUpdated, [field]: value });
  };

  const checkDisabled = () => {
    return !clientUpdated.company_name ||
      !clientUpdated.vat_number ||
      !clientUpdated.fiscal_code ||
      !clientUpdated.unique_invoice_code ||
      !clientUpdated.legal_address ||
      !clientUpdated.legal_city ||
      !clientUpdated.legal_postal_code;
  };

  const submitUpdateClient = async (e: any) => {
    try {
      e.preventDefault();
      await updateClientSelf({ client: clientUpdated }).unwrap();
      dispatch(setLoggedClient(clientUpdated));
      toast.success("Dati di fatturazione aggiornati con successo");
      setShowBillingForm(false);
      handleRequestInvoice();
    } catch (err) {
      toast.error("Errore nell'aggiornamento dei dati di fatturazione");
      console.log(err);
    }
  };

  const handleRequestInvoice = () => {
    if (requestInvoice && client) {
      const missingBillingData = !client.company_name ||
        !client.vat_number ||
        !client.fiscal_code ||
        !client.unique_invoice_code ||
        !client.legal_address ||
        !client.legal_city ||
        !client.legal_postal_code;
      if (missingBillingData) {
        setShowBillingForm(true);
        return;
      }
    }

    setRequestInvoice(!requestInvoice);
    const updatedBookings = bookings.map((booking: any) => ({ ...booking, request_invoice: !requestInvoice }));
    const updatedEvents = events.map((event: any) => ({ ...event, request_invoice: !requestInvoice }));
    dispatch(setBookings(updatedBookings));
    dispatch(setEvents(updatedEvents));
  }

  const handleBooking = async () => {
    try {
      if (bookings) {
        const res = await startBooking({ bookings, events }).unwrap();
        setClientSecret(res.session.client_secret)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmitRequestInvoice = () => {
    setRequestingInvoice(false);
    handleBooking();
  }

  if (requestingInvoice) {
    return (
      <CommonLayout
        index="3"
        backtHref={prevUrl}
        canGoNext={false}
      >
        <div className="flex flex-col items-center justify-center h-full p-6">
          <div className="flex items-center justify-between w-auto mb-4 gap-3">
            <Checkbox
              name="requestInvoice"
              label="Vuoi richiedere la fattura?"
              isChecked={requestInvoice}
              onChange={handleRequestInvoice}
            />
          </div>
          <ButtonPrimary onClick={handleSubmitRequestInvoice} className="w-64 mt-4">Vai al pagamento</ButtonPrimary>

          <Transition.Root show={showBillingForm} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => setShowBillingForm(false)}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-30" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-out duration-300"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition ease-in duration-200"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Dialog.Panel className="relative transform rounded-2xl bg-white shadow-xl p-6">
                      <h3 className="text-2xl font-semibold mb-4">Inserisci i dati di fatturazione</h3>
                      <form onSubmit={submitUpdateClient}>
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                              Ragione sociale
                            </label>
                            <Input
                              required
                              value={clientUpdated?.company_name || ""}
                              onChange={(e) => handleUpdateClient("company_name", e.target.value)}
                              className="mt-1"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                              Partita IVA
                            </label>
                            <Input
                              required
                              value={clientUpdated?.vat_number || ""}
                              onChange={(e) => handleUpdateClient("vat_number", e.target.value)}
                              className="mt-1"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                              Codice Fiscale
                            </label>
                            <Input
                              required
                              value={clientUpdated?.fiscal_code || ""}
                              onChange={(e) => handleUpdateClient("fiscal_code", e.target.value)}
                              className="mt-1"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                              Codice univoco fatturazione
                            </label>
                            <Input
                              required
                              value={clientUpdated?.unique_invoice_code || ""}
                              onChange={(e) => handleUpdateClient("unique_invoice_code", e.target.value)}
                              className="mt-1"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                              Indirizzo legale
                            </label>
                            <Input
                              required
                              value={clientUpdated?.legal_address || ""}
                              onChange={(e) => handleUpdateClient("legal_address", e.target.value)}
                              className="mt-1"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                              Città sede legale
                            </label>
                            <Input
                              required
                              value={clientUpdated?.legal_city || ""}
                              onChange={(e) => handleUpdateClient("legal_city", e.target.value)}
                              className="mt-1"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                              CAP sede legale
                            </label>
                            <Input
                              required
                              value={clientUpdated?.legal_postal_code || ""}
                              onChange={(e) => handleUpdateClient("legal_postal_code", e.target.value)}
                              className="mt-1"
                            />
                          </div>
                        </div>
                        <div className="flex justify-end gap-4 mt-6">
                          <ButtonPrimary onClick={() => setShowBillingForm(false)} type="button">
                            Annulla
                          </ButtonPrimary>
                          <ButtonPrimary type="submit" disabled={checkDisabled()}>
                            Salva
                          </ButtonPrimary>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </CommonLayout>
    )
  }

  if (clientSecret?.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600 mt-4" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
      </div>
    )
  }

  return (
    <CommonLayout
      index="3"
      backtHref={prevUrl}
      canGoNext={false}
    >
      <div className="p-3">
        <CheckoutForm
          bookings={bookings}
          clientSecret={clientSecret}
        />
      </div>
    </CommonLayout>
  )
};

export default PaymentStep;
