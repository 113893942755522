import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Heading from 'components/Heading/Heading';
import { useGetBookingDetailsQuery } from 'features/bookings/bookingApiSlice';

interface BookingInvoiceData {
    booking_uuid: string;
    client_uuid: string;
    client_name: string;
    client_email: string;
    client_phone: string;
    client_company_name: string;
    client_vat_number: string;
    client_legal_address: string;
    client_legal_city: string;
    client_legal_postal_code: string;
    client_unique_invoice_code: string;
    client_fiscal_code: string;
    check_in: string;
    check_out: string;
    price: number;
    guests: number;
    status: string;
    property_name: string;
    property_address: string;
    property_city: string;
    client_address?: string;
    client_city?: string;
    client_postal_code?: string;
}

const InvoiceDetailsPage: React.FC = () => {
    const { bookingId } = useParams<{ bookingId: string }>();
    const { data: booking, isLoading } = useGetBookingDetailsQuery(bookingId || '');

    if (isLoading) {
        return <div>Caricamento...</div>;
    }

    const invoiceData = booking as BookingInvoiceData;

    return (
        <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-8 flex flex-col">
            <Helmet>
                <title>Dettagli fatturazione | TRIBYOU</title>
            </Helmet>

            <Heading
                desc="Dettagli per la fatturazione della prenotazione"
                className="mb-8"
            >
                Dati di fatturazione
            </Heading>

            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Prenotazione #{bookingId}
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        Stato: {booking?.status}
                    </p>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Dettagli Prenotazione
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <p>Check-in: {new Date(booking?.check_in).toLocaleDateString('it-IT')}</p>
                                <p>Check-out: {new Date(booking?.check_out).toLocaleDateString('it-IT')}</p>
                                <p>Ospiti: {booking?.guests}</p>
                                <p>Prezzo: €{booking?.price}</p>
                            </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Struttura
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <p>{booking?.property_name}</p>
                                <p>{booking?.property_address}</p>
                                <p>{booking?.property_city}</p>
                            </dd>
                        </div>

                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Dati Cliente
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <p>{booking?.client_name}</p>
                                <p>{booking?.client_email}</p>
                                <p>{booking?.client_phone}</p>
                            </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Ragione sociale
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {booking?.client_company_name}
                            </dd>
                        </div>

                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Partita IVA
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {booking?.client_vat_number}
                            </dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Sede Legale
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {`${booking?.client_legal_address}, ${booking?.client_legal_city} - ${booking?.client_legal_postal_code}`}
                            </dd>
                        </div>

                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Codice Univoco
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {booking?.client_unique_invoice_code}
                            </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Codice Fiscale
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {booking?.client_fiscal_code}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default InvoiceDetailsPage; 