import { Dialog, Transition } from '@headlessui/react'
import { ArrowLeftCircleIcon, ArrowLeftIcon, XCircleIcon } from '@heroicons/react/24/outline';
import GallerySlider from 'components/GallerySlider/GallerySlider';
import StartRating from 'components/StartRating/StartRating';
import { Fragment, useEffect, useState } from 'react'
import React, { FC } from "react";
import Skeleton from 'react-loading-skeleton';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

export interface SummaryDrawerProps {
  className?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  experiences: any;
  property: any;
  closeModal: () => void;
}

const SummaryDrawer: FC<SummaryDrawerProps> = ({
  className = "",
  children,
  isOpen,
  experiences,
  property,
  closeModal
}) => {

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[99]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex h-full items-center justify-end  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-full max-w-md transform md:rounded-s-2xl bg-white p-8 text-left align-middle shadow-xl transition-all overflow-scroll ">
                  <div className='flex flex-row items-center gap-3'>
                    <XCircleIcon
                      color='#CCCCCC'
                      onClick={closeModal}
                      className="h-8 w-8 cursor-pointer"
                    />
                    <Dialog.Title
                      as="h2"
                      className="text-3xl font-semibold"
                    >
                      {property?.name}
                    </Dialog.Title>

                  </div>
                  <div className="">
                    <p className="text-sm text-gray-500">
                      {property?.description}
                    </p>
                  </div>
                  <img src={property.cover} alt="property" className="w-full h-auto rounded-t-md my-2" />

                  <Dialog.Title
                    as="h2"
                    className="text-2xl font-semibold mt-4 "
                  >
                    Esperienze:
                  </Dialog.Title>
                  <div className='w-full h-[1px] bg-neutral-300 my-4' />
                  {
                    experiences?.map((experience: any) => {
                      return (
                        <div key={experience.experience_uuid} className='border-b-[1px] border-b-neutral-300 pb-2 my-3'>
                          <div>
                            <Dialog.Title
                              as="h4"
                              className="text-lg font-semibold"
                            >
                              {experience?.name}
                            </Dialog.Title>
                          </div>
                          <div className="flex items-center space-x-4 text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                            <StartRating reviewCount={experience?.reviews_count ? experience.reviews_count : 0} point={experience?.rating ? experience.rating : 0} />
                            <span>· </span>
                            <div className="flex items-center">
                              <span className="hidden sm:inline-block  text-base">
                                <i className="las la-map-marked"></i>
                              </span>
                              <span className="sm:ml-2"> {experience?.address}</span>
                            </div>
                          </div>
                          <div className="mt-4">
                            <p className="text-sm text-gray-500">
                              {experience?.description}
                            </p>
                          </div>

                          <div className='mt-4'>
                            <GallerySlider
                              galleryImgs={experience?.media}
                              uniqueID={`SideDrawer_${experience?.experience_uuid}`}
                            />
                          </div>

                          <div className='flex flex-col gap-1'>
                            <h4 className="font-semibold mt-4 text-xl">Durata:</h4>
                            <div className='flex flex-row gap-2 items-center'>
                              <i className="las la-clock text-md"></i>
                              <p className="text-md text-gray-500">
                                {experience?.minutes_duration} minuti
                              </p>
                            </div>
                          </div>

                          <div className='flex flex-col gap-1'>
                            <h4 className="font-semibold mt-4 text-xl">Posti:</h4>
                            <div className='flex flex-row gap-2 items-center'>
                              <i className="las la-user text-md"></i>
                              <p className="text-md text-gray-500">
                                {experience?.max_guests} persone
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }


                </Dialog.Panel >
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SummaryDrawer;
