import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Heading from "components/Heading/Heading";
import { useGetHostBookingsQuery, useLazyGetHostBookingsQuery } from "features/host/hostApiSlice";
import moment from "moment";
import Select from "shared/Select/Select";
import Skeleton from "react-loading-skeleton";
import BookingModal from "components/BookingModal/BookingModal";
import { useRejectBookingMutation } from "features/bookings/bookingApiSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const PageHostReservations: React.FC = () => {
  const [getBookings, { data: bookings, isLoading, isError, isFetching }] = useLazyGetHostBookingsQuery({});
  const [rejectBooking, { isLoading: isRejecting }] = useRejectBookingMutation();

  const [filter, setFilter] = React.useState<string>("all");
  const [booking, setBooking] = React.useState<any>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleGetBookings = async () => {
    try {
      await getBookings(filter).unwrap();
    } catch (err) {
      console.log(err);
    }
  }
  const handleRejectBookings = async (booking_uuid: string) => {
    try {
      const res = await rejectBooking(booking_uuid).unwrap();
      setIsOpen(false)
      toast.success("Prenotazione annullata con successo");
      // after 700 ms reload the page
      setTimeout(() => {
        window.location.reload()
      }, 700);
    } catch (error:any) {
      if (error.data) {
        toast.error(error.data.message);
        return;
      }
      toast.error("Errore durante l'annullamento della prenotazione");
      console.log(error);
    }
  }
  const handleStatusFlag = (status: string) => {
    switch (status) {
      case "confirmed":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
            Confermata
          </span>
        )
      case "completed":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
            Completata
          </span>
        )
      case "canceled":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
            Cancellata
          </span>
        )
      case "refunded":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
            Rimborsata
          </span>
        )
      case "rejected":
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            Rifiutata
          </span>
        )
    }
  }

  useEffect(() => {
    handleGetBookings();
  }, [filter]);

  useEffect(() => {
    if (booking) {
      setIsOpen(true);
    }
  }, [booking]);

  return (
    <div className="nc-annunci">
      <Helmet>
        <title>Le mi prenotazioni | TRIBYOU</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-8 flex flex-col">
        <Heading desc={"Le prenotazioni che hai ricevuto su TRIBYOU"} className="mb-8">
          Le mie prenotazioni
        </Heading>


        <Select
          className="!w-44 mb-5"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="all">Tutte le prenotazioni</option>
          <option value="confirmed">Confermate</option>
          <option value="completed">
            Completate
          </option>
          <option value="canceled">
            Cancellate
          </option>
          <option value="refunded">
            Rimborsate
          </option>
          <option value="rejected">
            Rifiutate
          </option>
        </Select>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Nome
                </th>
                <th scope="col" className="px-6 py-3">
                  Oggetto
                </th>
                <th scope="col" className="px-6 py-3">
                  Ospiti
                </th>
                <th scope="col" className="px-6 py-3">
                  Data di inizio
                </th>
                <th scope="col" className="px-6 py-3">
                  Data di fine
                </th>
                <th scope="col" className="px-6 py-3">
                  Stato
                </th>
                <th scope="col" className="px-6 py-3">
                  Richiesta fattura
                </th>
                <th scope="col" className="px-6 py-3">
                  Azioni
                </th>
              </tr>
            </thead>
            <tbody>
              {
                isFetching ?
                  // map 4 skeleton
                  [1, 2, 3, 4].map((i) => {
                    return (
                      <tr
                        key={i}
                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          <Skeleton width={100} />
                        </th>
                        <td className="px-6 py-4">
                          <Skeleton width={100} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton width={100} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton width={100} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton width={100} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton width={100} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton width={100} />
                        </td>
                        <td className="px-6 py-4">
                          <Skeleton width={100} />
                        </td>
                      </tr>
                    )
                  })
                  :
                  bookings?.map((booking: any) => {
                    return (
                      <tr
                        key={booking?.booking_uuid}
                        className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {booking?.client_name}
                        </th>
                        <td className="px-6 py-4">
                          {booking?.name}
                        </td>
                        <td className="px-6 py-4">
                          {booking?.guests}
                        </td>
                        <td className="px-6 py-4">
                          {moment(booking?.check_in).format("DD/MM/YYYY")}
                          {booking.start_at && ` - ${booking.start_at}`}
                        </td>
                        <td className="px-6 py-4">
                          {moment(booking?.check_out).format("DD/MM/YYYY")}
                        </td>
                        <td className="px-6 py-4">
                          {handleStatusFlag(booking?.status)}
                        </td>
                        <td className="px-6 py-4">
                          {booking?.request_invoice ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Si
                            </span>
                          ) : (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                              No
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex flex-col gap-2">
                            {moment().diff(booking?.created_at, 'hours') < 48 && booking?.status === "confirmed" && (
                              <button
                                onClick={() => setBooking(booking)}
                                className="font-medium text-primary-6000 dark:text-primary-6000 hover:underline"
                              >
                                Cancella prenotazione
                              </button>
                            )}
                            {booking?.request_invoice && (
                              <Link 
                                to={`/host/invoice/${booking?.booking_uuid}`}
                                className="font-medium text-primary-6000 dark:text-primary-6000 hover:underline"
                              >
                                Visualizza dati fatturazione
                              </Link>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })
              }
            </tbody>
          </table>
        </div>
      </div>
      <BookingModal
        isOpen={isOpen}
        isLoading={isRejecting}
        closeModal={() => setIsOpen(false)}
        cancelBooking={() => handleRejectBookings(booking?.booking_uuid)}
        booking={booking}
      />
    </div>
  )
}

export default PageHostReservations;
