import React, { FC, useEffect, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import GoogleMapReact from "google-map-react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCardH from "components/ExperiencesCardH/ExperiencesCardH";
import { useLazyFilterExperienceQuery } from "features/experience/experienceApiSlice";
import { useDispatch } from "react-redux";
import { setExperiencesList } from "features/experience/experienceSlice";
import Skeleton from "react-loading-skeleton";
import SideDrawer from "components/SideDrawer/SideDrawer";

const DEMO_EXPERIENCES = DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 12);

export interface SectionGridProps {
  data?: any;
  region?: any;
  latitude?: any;
  longitude?: any;
}

const SectionGrid: FC<SectionGridProps> = ({ data, latitude, longitude, region }) => {
  const [filterExperiences, { data: filterExperienceData, isFetching: isLoadingFilterExperience }] = useLazyFilterExperienceQuery({});
  const [showSkeleton, setShowSkeleton] = useState(true);

  const dispatch = useDispatch();

  const handleFilter = async (selectedType: any, latitude: any, longitude: any, radius: number, rangePrices: any, selectedMoment: any) => {
    try {
      const response = await filterExperiences({ type: selectedType, latitude: latitude, longitude: longitude, radius, price: rangePrices, moment: selectedMoment }).unwrap();
      dispatch(setExperiencesList(response));
    } catch (e) {
    }
  }
  useEffect(() => {
    if (isLoadingFilterExperience) {
      setShowSkeleton(true);
    }
    else {
      const skeletonTimeout = setTimeout(() => {
        setShowSkeleton(false);
      }, 700); // Mostra gli skeleton per almeno un secondo
      return () => {
        clearTimeout(skeletonTimeout);
      };
    }
  }, [isLoadingFilterExperience]);

  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showModal, setShowModal] = useState(false);
  const [drawerItem, setDrawerItem] = useState<any>({});

  const handleOpenDrawer = (item: any) => {
    setShowModal(true);
    setDrawerItem(item);
  }

  return (
    <div>
      <div className="relative flex">
        {/* CARDSSSS */}
        <div className="h-auto w-full xl:w-full flex-shrink-0 xl:px-0">
          <div className="mb-8 lg:mb-11">
            <TabFilters handleFilter={handleFilter} latitude={latitude} longitude={longitude} />
          </div>
          <div className="grid grid-cols-1 gap-8">
            {
              isLoadingFilterExperience || showSkeleton ?
                Array.from(Array(3).keys()).map((item: any, index) => (
                  <div key={index} className="w-full md:w-1/3 lg:w-full flex flex-row items-start justify-between p-2 md:p-4 gap-2 bg-white flex-wrap">
                    <div className="md:basis-1/3 basis-full">
                      <Skeleton className="w-full h-[15rem]" />
                    </div>
                    <div className="md:basis-2/3 basis-full flex flex-col">
                      <Skeleton className="w-full h-[3rem]" />
                      <Skeleton className="w-full h-[5rem] mt-3" />
                      <div className="w-[10rem] self-end mt-3">
                        <Skeleton className="w-full h-[3rem]" />
                      </div>
                    </div>

                  </div>
                ))
                :
                filterExperienceData ?
                  filterExperienceData.map((item: any) => (
                    <div
                      key={item.experience_uuid}
                      onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                      onMouseLeave={() => setCurrentHoverID((_) => -1)}
                    >
                      <ExperiencesCardH
                        data={item}
                        secondaryCta={() => handleOpenDrawer(item)}
                      />
                    </div>
                  ))
                  :
                  data.map((item: any) => (
                    <div
                      key={item.experience_uuid}
                      onMouseEnter={() => setCurrentHoverID((_) => item.id)}
                      onMouseLeave={() => setCurrentHoverID((_) => -1)}
                    >
                      <ExperiencesCardH
                        data={item}
                        secondaryCta={() => handleOpenDrawer(item)}
                      />
                    </div>
                  ))

            }

          </div>
          {/* 
           <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div>
          */}
          <SideDrawer
            isOpen={showModal}
            experience={drawerItem}
            closeModal={() => setShowModal(false)}
          />
        </div>

      </div>
    </div>
  );
};

export default SectionGrid;
