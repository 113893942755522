import React, { FC, useEffect, useRef, useState } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import moment from 'moment'
import { useLazyGetAvailablePropertiesByDateQuery } from "features/property/propertyApiSlice";
import { useSelector } from "react-redux";
import { selectCheckIn, selectCheckOut, selectCity, selectCountry, selectGuests, selectLatitude, selectLongitude, selectMood, selectRegion, selectSearchResults, selectSearchType, selectTrend, setSearchResults } from "features/search/searchSlice";
import { useDispatch } from "react-redux";
import useWindowSize from "hooks/useWindowResize";
import { useSearchPropertiesQuery } from "features/search/searchApiSlice";
import { useLocation, useParams } from "react-router-dom";
import Lottie from 'react-lottie';
import * as EmptyAnimation from "../../images/lotties/empty.json";
import { useLazyFilterExperienceQuery } from "features/experience/experienceApiSlice";
import GoogleMapReact from 'google-map-react';
import Marker from 'components/AnyReactComponent/Marker';

export interface ListingStayMapPageProps {
  className?: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({
  className = "",
}) => {
  const mood = useSelector(selectMood);
  const city = useSelector(selectCity);
  const region = useSelector(selectRegion);
  const check_in = useSelector(selectCheckIn);
  const check_out = useSelector(selectCheckOut);
  const guests = useSelector(selectGuests);
  const latitude = useSelector(selectLatitude);
  const longitude = useSelector(selectLongitude);
  const searchType = useSelector(selectSearchType);
  const trend = useSelector(selectTrend);
  const country = useSelector(selectCountry);

  const location = useLocation();

  // Funzione per analizzare la stringa della query
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const { defaultType } = useParams();


  const query = useQuery();

  // Estrazione dei parametri
  const city_q = query.get('city');
  const lat_q = query.get('latitude');
  const lng_q = query.get('longitude');
  const region_q = query.get('region');
  const country_q = query.get('country');
  const check_in_q = query.get('check_in');
  const check_out_q = query.get('check_out');
  const guests_q = query.get('guests');
  const mood_q = query.get('mood');
  const searchType_q = query.get('searchType');
  const trend_q = query.get('trend');

  const searchResultRef = React.useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const searchResults = useSelector(selectSearchResults)
  const [filterExperiences, { data: experiences, isLoading: isLoadingExperiences, isFetching: isFetchingExperiencs, isSuccess: isSuccessExperiences }] =
    useLazyFilterExperienceQuery({});

  const [searchProperties, { data, isLoading, isFetching, isSuccess }] = useLazyGetAvailablePropertiesByDateQuery({});
  const [showLoader, setShowLoader] = useState(false);

  const { width } = useWindowSize();
  const isSmallDevice = width < 768;

  const handleSearch = async () => {
    try {
      if (searchType === "experiences") {
        const response = await filterExperiences({
          latitude: lat_q || latitude,
          longitude: lng_q || longitude,
          region: region_q,
          check_in: check_in_q,
          check_out: check_out_q,
          country: country_q,
          guests: guests_q,
          mood: mood_q,
          radius: 100
        }).unwrap();
        dispatch(setSearchResults(response));
      } else {
        const response = await searchProperties({
          latitude: lat_q || latitude,
          longitude: lng_q || longitude,
          region: region_q,
          country: country_q,
          check_in: check_in_q,
          check_out: check_out_q,
          guests: guests_q,
          mood: mood_q,
          trend: trend_q
        }).unwrap();
        dispatch(setSearchResults(response));
        window.scrollTo(0, 0)
      }
    } catch (error) {
      console.error("Failed to search properties", error);
    }
  }
  const handleSearchManual = async () => {
    try {
      if (searchType === 0 || searchType === "experiences") {
        const response = await filterExperiences({
          latitude: latitude,
          longitude: longitude,
          check_in: check_in,
          check_out: check_out,
          guests: guests,
          mood: mood,
          region: region,
          country: country,
          radius: 100
        }).unwrap();
        dispatch(setSearchResults(response));
      } else {
        const response = await searchProperties({
          latitude: latitude,
          longitude: longitude,
          check_in: check_in,
          check_out: check_out,
          guests: guests,
          mood: mood,
          region: region,
          country: country,
          trend: trend,

        }).unwrap();
        dispatch(setSearchResults(response));
      }
      if (searchResultRef.current) {
        searchResultRef.current.scrollIntoView({ behavior: "smooth" });
      }

    } catch (error) {
      console.error("Failed to search properties", error);
    }
  }

  useEffect(() => {
    if (!isFetching) {
      const timer = setTimeout(() => setShowLoader(false), 500); // 300ms di ritardo
      return () => clearTimeout(timer);
    } else {
      setShowLoader(true);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFetchingExperiencs) {
      const timer = setTimeout(() => setShowLoader(false), 500); // 300ms di ritardo
      return () => clearTimeout(timer);
    } else {
      setShowLoader(true);
    }
  }, [isFetchingExperiencs]);

  useEffect(() => {
    if (isSmallDevice) {
      handleSearch()
    }
  }, [lat_q, lng_q, region_q, check_in_q, check_out_q, guests_q, searchType_q, trend_q, country_q])

  return (
    <div
      className={`nc-ListingStayMapPage relative ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet>
        <title>TRIBYOU</title>
      </Helmet>

      <BgGlassmorphism />


      <div className="container md:pt-10 pb-24 lg:pt-16 lg:pb-28" >
        {
          !isSmallDevice &&
          <SectionHeroArchivePage
            currentPage="Stays"
            defaultType={defaultType ? parseInt(defaultType) : 1}
            currentTab="Stays"
            className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
            handleSearch={handleSearchManual}
          />
        }
      </div>

      {/* SECTION */}
      <div className="container lg:pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none" ref={searchResultRef}>
        {
          isSmallDevice && !searchResults && isSuccess &&
          <div className="flex flex-col items-center justify-center space-y-8 py-24 lg:py-28">
            <Lottie options={{
              loop: true,
              autoplay: true,
              animationData: EmptyAnimation,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
              height={200}
              width={200}
              isStopped={false}
              isPaused={false} />

            <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100">Nessun risultato trovato</h2>
          </div>
        }
        {
          searchResults && isSmallDevice &&
          <SectionGridHasMap
            isLoading={showLoader}
            data={searchResults}
            city={city_q || city || ""}
            region={region_q || region || ""}
            check_in={check_in_q || check_in || ""}
            check_out={check_out_q || check_out || ""}
            guests={guests_q ? parseInt(guests_q) : guests ? guests : 1}
            className="pb-24 lg:pb-28"
            cardType={searchType === 0 || searchType === "experiences" ? "card5" : "card3"}
          />
        }
        {
          !isSmallDevice && (isSuccess || isSuccessExperiences) &&
          <SectionGridHasMap
            cardType={searchType === 0 || searchType === "experiences" ? "card5" : "card3"}
            isLoading={showLoader}
            data={searchResults}
            city={city_q || city || ""}
            region={region_q || region || ""}
            check_in={check_in_q || check_in || ""}
            check_out={check_out_q || check_out || ""}
            guests={guests_q ? parseInt(guests_q) : guests ? guests : 1}
            className="pb-24 lg:pb-28"

          />
        }
      </div>

      <div className="container overflow-hidden mb-[5rem]">
        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Le destinazioni più belle d'Italia"
            subHeading="Scopri le destinazione più belle d'Italia presenti su Tribyou"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
          />
        </div>
      </div>

      <GoogleMapReact
        defaultZoom={12}
        center={{ 
          lat: searchResults?.[0]?.latitude || latitude, 
          lng: searchResults?.[0]?.longitude || longitude 
        }}
        bootstrapURLKeys={{
          key: "AIzaSyCVgu-JWoz3RsKhafHgSffRNKPBngBETxs",
        }}
        yesIWantToUseGoogleMapApiInternals
        options={{
          fullscreenControl: false,
          zoomControl: true,
          minZoom: 10,
          maxZoom: 20
        }}
      >
        {searchResults?.map((item: any) => (
          <Marker
            key={item?.property_uuid}
            lat={item?.latitude}
            lng={item?.longitude}
            className="absolute transform -translate-x-1/2 -translate-y-1/2"
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default ListingStayMapPage;
