import React, { FC, useState, useRef, useEffect } from "react";
import CommonLayout from "./CommonLayout";
import { usePageAddListingContext } from "contexts/PageAddListingContext";
import { useUploadPropertyAlbumImageMutation, useUploadPropertyCoverMutation } from "features/property/propertyApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentproperty, setproperty } from "features/property/propertySlice";
import Skeleton from "react-loading-skeleton";

export interface PageAddListing7Props { }

const PageAddListing7: FC<PageAddListing7Props> = () => {
  const [cover, setCover] = useState<string>("");
  const [album, setAlbum] = useState<Array<string>>([]);

  const coverRef = useRef<HTMLInputElement>(null);

  const property = useSelector(selectCurrentproperty)
  const [updateCover, { isLoading: uploadingCover }] = useUploadPropertyCoverMutation()
  const [updateAlbumImage, { isLoading: uploadingAlbumImage }] = useUploadPropertyAlbumImageMutation()
  const dispatch = useDispatch()

  const handleUpdateProperty = (value: any) => {
    try {

    }
    catch (e) {
      console.log(e)
    }
  };

  const uploadCover = async (e: any) => {
    try {
      // if file size is greater than 4mb throw error
      if (e.target.files[0].size > 10000000) {
        throw alert('Inserisci un file di dimensioni inferiori a 10mb')
      }
      const url = URL.createObjectURL(e.target.files[0]);
      setCover(url)

      const formData = new FormData()
      formData.append('property-cover', e.target.files[0])

      const response = await updateCover(formData).unwrap();
      dispatch(setproperty({ ...property, cover: response.result.s3Location }))
    } catch (err) {
      console.log(err)
    }
  }

  const uploadAlbumImage = async (e: any) => {
    try {
      if (e.target.files[0].size > 10000000) {
        throw alert('Inserisci un file di dimensioni inferiori a 10mb')
      }
      const url = URL.createObjectURL(e.target.files[0]);
      setAlbum([...album, url])

      const formData = new FormData()
      formData.append('property-album-image', e.target.files[0])

      const response = await updateAlbumImage(formData).unwrap();

      dispatch(setproperty({ ...property, media: [...property.media, response.result.s3Location] }))
    } catch (err) {
      console.log(err)
    }
  }

  const handleRemoveCover = () => {
    if (coverRef.current) {
      coverRef.current.value = "";
    }
    dispatch(setproperty({ ...property, cover: null }))
  }

  const CustomPicker = (url: string) => {
    if (url && url.length > 0) {
      return (
        <div>
          <img src={url} alt="" className="w-auto max-h-80" />
          <div className="absolute top-0 right-0 z-50">
            <button
              onClick={() => {
                handleRemoveCover()
              }}
              className="bg-neutral-100 dark:bg-neutral-800 rounded-full p-1"
            >
              <svg
                className="w-4 h-4 text-neutral-6000 dark:text-neutral-300"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
          <div
            className="space-y-1 text-center relative">
            <svg
              className="mx-auto h-12 w-12 text-neutral-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
            <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
              <label
                htmlFor="file-upload"
                className="relative flex justify-center cursor-pointer text-center  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
              >
                <span>Carica qui</span>
              </label>
              <p className="pl-1">il tuo file</p>
            </div>
            <p className="text-xs text-neutral-500 dark:text-neutral-400">
              PNG, JPG, JPEG non più di 4MB
            </p>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    if (!property?.media) {
      dispatch(setproperty({ ...property, media: [] }))
    }
  }, [])

  return (
    <CommonLayout
      index="07"
      backtHref="/host/new-property/add-listing-6"
      nextHref="/host/new-property/add-listing-8"
      canGoNext={property?.cover?.length > 0 && !uploadingCover && !uploadingAlbumImage}
    >
      <>
        <div>
          <h2 className="text-2xl font-semibold">Foto del tuo alloggio</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Qualche foto del tuo alloggio aiuta gli ospiti a farsi un'idea di come sia.
            Includi almeno una foto della camera da letto, del bagno e della cucina.
          </span>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          <div>
            <span className="text-lg font-semibold">Immagine di copertina</span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              carica qui l'immagine più evocativa della tua struttura, sarà la prima a essere visualizzata durante la ricerca degli alloggi.
            </span>
            <div className="mt-5 relative ">
              {
                uploadingCover ?
                  <div className="w-full h-32 ">
                    <Skeleton className="h-full rounded-full" />
                  </div>
                  :
                  CustomPicker(property.cover)
              }
              <input
                disabled={uploadingAlbumImage || uploadingCover}
                type="file"
                ref={coverRef}
                accept="image/*"
                name="property-cover"
                className="absolute w-full h-full z-40 opacity-0 top-0"
                onChange={(e) => e.target.files && uploadCover(e)}
              />
            </div>
          </div>
          {/* ----------------- */}
          <div>
            <span className="text-lg font-semibold">Foto dell'annuncio</span>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              carica qui le foto del tuo alloggio, comprendendo ogni stanza.
              le foto devono essere orizzontali, ben illuminata, minimo 1024×512 pixel
            </span>
            <div className="mt-5 relative ">
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <label
                      htmlFor="file-upload-2"
                      className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Upload a file</span>
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-neutral-500 dark:text-neutral-400">
                    PNG, JPG, GIF up to 4MB
                  </p>
                </div>
              </div>
              <input
                id="file-upload-2"
                type="file"
                accept="image/*"
                name="property-album-image"
                className="absolute w-full h-full z-40 opacity-0 top-0"
                disabled={uploadingAlbumImage || uploadingCover}
                onChange={(e) => e.target.files && uploadAlbumImage(e)}
              />
            </div>
            <div className="w-full mt-2">
              {
                uploadingAlbumImage ?
                  <div className="w-full h-32 ">
                    <Skeleton className="h-full rounded-full" />
                  </div>
                  :
                  <div className="grid grid-cols-3 gap-4 mt-4">
                    {
                      property?.media?.map((item: string, index: number) => (
                        <div key={index} className="relative">
                          <img src={item} alt="" className="w-full h-32 object-cover rounded-md" />
                          <div className="absolute top-0 right-0">
                            <button
                              onClick={() => {
                                const updatedAlbum = property.media.filter((i: string) => i !== item)
                                dispatch(setproperty({ ...property, media: updatedAlbum }))
                              }}
                              className="bg-neutral-100 dark:bg-neutral-800 rounded-full p-1"
                            >
                              <svg
                                className="w-4 h-4 text-neutral-6000 dark:text-neutral-300"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      ))
                    }
                  </div>
              }
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing7;
