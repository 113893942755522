import { Dialog, Tab, Transition } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import moment from "moment";
import { useDispatch } from "react-redux";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useChangePasswordMutation, useGetMeClientQuery, useUpdateClientSelfMutation } from "features/client/clientApiSlice";
import { setLoggedClient } from "features/clientAuth/authClientSlice";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import WorkCategories from "containers/PageAddClientInfo/WorkCategories";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { toast } from "react-toastify";
import InputPsw from "shared/InputPsw/InputPsw";

export interface ClientPageProps {
  className?: string;
}

const ClientPage: FC<ClientPageProps> = ({ className = "" }) => {
  const { data: client, isLoading, isError } = useGetMeClientQuery({})
  const [updateClientSelf, { data, isLoading: updatingClient }] = useUpdateClientSelfMutation({})
  const [changePassword, { data: changePasswordData, isLoading: changingPassword }] = useChangePasswordMutation({})

  const [clientUpdated, setClientUpdated] = useState<any>({
    ...client
  })
  const [showModal, setShowModal] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [sameAsResidence, setSameAsResidence] = useState(false);

  const dispatch = useDispatch()

  let [categories] = useState(["Pacchetti", "Strutture", "Esperienze"]);

  const handleUpdateClient = (field: string, value: any) => {
    let newClient = { ...client, [field]: value }
    setClientUpdated(newClient);
  }

  const submitUpdateClient = async (e: any) => {
    try {
      e.preventDefault()
      updateClientSelf({ client: clientUpdated }).unwrap();
      dispatch(setLoggedClient(clientUpdated))
      toast.success("Dati aggiornati con successo")
    }
    catch (err) {
      toast.error("Errore nell'aggiornamento dei dati")
      console.log(err)
    }
  }

  const handleChangePassword = async () => {
    try {
      const changePsw = await changePassword({ oldPassword, newPassword }).unwrap();
      toast.success("Password cambiata con successo")
    }
    catch (err) {
      console.log(err);
      toast.error("Errore nel cambio della password")
    }
  }

  const checkDisabled = () => {
    if (
      clientUpdated.name === client.name && 
      clientUpdated.email === client.email && 
      clientUpdated.phone === client.phone && 
      clientUpdated.description === client.description && 
      clientUpdated.city === client.city && 
      clientUpdated.address === client.address && 
      clientUpdated.work === client.work &&
      clientUpdated.company_name === client.company_name &&
      clientUpdated.vat_number === client.vat_number &&
      clientUpdated.fiscal_code === client.fiscal_code &&
      clientUpdated.unique_invoice_code === client.unique_invoice_code &&
      clientUpdated.legal_address === client.legal_address &&
      clientUpdated.legal_city === client.legal_city &&
      clientUpdated.legal_postal_code === client.legal_postal_code
    ) {
      return true;
    }

    if (
      clientUpdated.name === "" || 
      clientUpdated.email === "" || 
      clientUpdated.phone === "" || 
      clientUpdated.description === "" || 
      clientUpdated.city === "" || 
      clientUpdated.address === "" || 
      clientUpdated.work === ""
    ) {
      return true;
    }
    return false;
  }

  const handleSameAsResidence = (checked: boolean) => {
    setSameAsResidence(checked);
    if (checked) {
      const updatedClient = {
        ...clientUpdated,
        legal_address: clientUpdated.address,
        legal_city: clientUpdated.city,
        legal_postal_code: clientUpdated.postal_code
      };
      setClientUpdated(updatedClient);
    } else {
      const updatedClient = {
        ...clientUpdated,
        legal_address: client.legal_address || '',
        legal_city: client.legal_city || '',
        legal_postal_code: client.legal_postal_code || '',
        company_name: client.company_name || '',
        vat_number: client.vat_number || '',
        fiscal_code: client.fiscal_code || '',
        unique_invoice_code: client.unique_invoice_code || ''
      };
      setClientUpdated(updatedClient);
    }
  };

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={client.avatar ? client.avatar : ""}
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{client.name}</h2>
          <StartRating point={client.rating} reviewCount={client.reviews_count} className="!text-base" />
        </div>

        {/* ---- */}
        <p className="text-neutral-500 dark:text-neutral-400">
          {client.description}
        </p>

        {/* ---- */}
        {client.socials &&
          <SocialsList
            className="!space-x-3"
            itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
          />
        }

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300 capitalize">
              {client.city} - {client.state}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            {
              client.created_at &&
              <span className="text-neutral-6000 dark:text-neutral-300">
                client TRIBYOU da {moment(client.created_at).format("DD MMM YYYY")}
              </span>
            }
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-3xl font-semibold">Gestisci i tuoi dati</h2>
        <form onSubmit={(e) => submitUpdateClient(e)}>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Nome
              </label>
              <Input
                id="name"
                name="name"
                type="text"
                placeholder="Nome"
                defaultValue={client.name}
                onChange={(e) => handleUpdateClient("name", e.target.value)}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Email
              </label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                defaultValue={client.email}
                onChange={(e) => handleUpdateClient("email", e.target.value)}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Telefono
              </label>
              <Input
                id="phone"
                name="phone"
                type="text"
                placeholder="Telefono"
                defaultValue={client.phone}
                onChange={(e) => handleUpdateClient("phone", e.target.value)}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Descrizione
              </label>
              <Input
                id="description"
                name="description"
                type="text"
                placeholder="Descrizione"
                onChange={(e) => handleUpdateClient("description", e.target.value)}
                defaultValue={client.description}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Città
              </label>
              <Input
                id="city"
                name="city"
                type="text"
                placeholder="Città"
                onChange={(e) => handleUpdateClient("city", e.target.value)}
                defaultValue={client.city}
                className="mt-1"
              />
            </div>
            <div>
              <label
                htmlFor="address"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Indirizzo
              </label>
              <Input
                id="address"
                name="address"
                type="text"
                placeholder="Indirizzo"
                onChange={(e) => handleUpdateClient("address", e.target.value)}
                defaultValue={client.address}
                className="mt-1"
              />
            </div>

            <div className="col-span-2">
              <label className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-primary-600"
                  checked={sameAsResidence}
                  onChange={(e) => handleSameAsResidence(e.target.checked)}
                />
                <span className="text-sm font-medium text-neutral-700 dark:text-neutral-300">
                  Sede legale uguale a residenza
                </span>
              </label>
            </div>
            <div className="col-span-2 grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="company_name"
                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                >
                  Ragione sociale
                </label>
                <Input
                  id="company_name"
                  name="company_name"
                  type="text"
                  placeholder="Ragione sociale"
                  value={clientUpdated.company_name || ''}
                  onChange={(e) => handleUpdateClient("company_name", e.target.value)}
                  className="mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="vat_number"
                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                >
                  Partita IVA
                </label>
                <Input
                  id="vat_number"
                  name="vat_number"
                  type="text"
                  placeholder="Partita IVA"
                  value={clientUpdated.vat_number}
                  onChange={(e) => handleUpdateClient("vat_number", e.target.value)}
                  className="mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="fiscal_code"
                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                >
                  Codice Fiscale
                </label>
                <Input
                  id="fiscal_code"
                  name="fiscal_code"
                  type="text"
                  placeholder="Codice Fiscale"
                  value={clientUpdated.fiscal_code}
                  onChange={(e) => handleUpdateClient("fiscal_code", e.target.value)}
                  className="mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="unique_invoice_code"
                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                >
                  Codice univoco fatturazione
                </label>
                <Input
                  id="unique_invoice_code"
                  name="unique_invoice_code"
                  type="text"
                  placeholder="Codice univoco fatturazione"
                  value={clientUpdated.unique_invoice_code}
                  onChange={(e) => handleUpdateClient("unique_invoice_code", e.target.value)}
                  className="mt-1"
                />
              </div>
              <div>
              <label
                htmlFor="work"
                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                Lavoro
              </label>
              <Select
                defaultValue={client.work}
                placeholder="Professione"
                onChange={(e) => handleUpdateClient("work", e.target.value)}
              >
                {
                  WorkCategories.map((p, i) => (
                    <option key={i} value={p}>{p}</option>
                  ))
                }
              </Select>
            </div>
              {!sameAsResidence && (
                <>
                  <div>
                    <label
                      htmlFor="legal_address"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    >
                      Indirizzo legale
                    </label>
                    <Input
                      id="legal_address"
                      name="legal_address"
                      type="text"
                      placeholder="Indirizzo legale"
                      value={clientUpdated.legal_address}
                      onChange={(e) => handleUpdateClient("legal_address", e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="legal_city"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    >
                      Città sede legale
                    </label>
                    <Input
                      id="legal_city"
                      name="legal_city"
                      type="text"
                      placeholder="Città sede legale"
                      value={clientUpdated.legal_city}
                      onChange={(e) => handleUpdateClient("legal_city", e.target.value)}
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="legal_postal_code"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    >
                      CAP sede legale
                    </label>
                    <Input
                      id="legal_postal_code"
                      name="legal_postal_code"
                      type="text"
                      placeholder="CAP sede legale"
                      value={clientUpdated.legal_postal_code}
                      onChange={(e) => handleUpdateClient("legal_postal_code", e.target.value)}
                      className="mt-1"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-end mt-6 gap-6">
            <span
              className="text-primary-6000 cursor-pointer hover:opacity-90"
              onClick={() => setShowModal(true)}
            >
              Cambia password
            </span>
            <ButtonPrimary
              type="submit"
              loading={updatingClient}
              disabled={checkDisabled()}
            >
              Salva
            </ButtonPrimary>
          </div>
        </form>
      </div>
    );
  };

  if (!client) {
    return <Skeleton className="h-[20rem]" />
  }
  return (
    <div className={`nc-ClientPage ${className}`} data-nc-id="ClientPage">
      <Helmet>
        <title>Mio profilo | TRIBYOU</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{!isLoading ? renderSidebar() : <Skeleton className="h-[20rem]" />}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {client && !isLoading ? renderSection2() : <Skeleton className="h-[20rem]" />}
        </div>
      </main>

      <>
        <Transition.Root show={showModal} as={Fragment}>
          <Dialog as="div" onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="transition ease-out duration-300"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition ease-in duration-200"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <div className="fixed inset-0 flex items-center justify-center p-5 md:p-0">
                <div className="bg-white rounded-lg p-10 flex items-start flex-col justify-center w-full md:w-[30rem]">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Cambia la tua password
                  </Dialog.Title>
                  <div className="w-full my-3">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                    >
                      Password attuale
                    </label>
                    <InputPsw
                      id="oldPassword"
                      onChange={(e) => setOldPassword(e.target.value)}
                      name="oldPassword"
                      type="password"
                      placeholder="Password"
                      className="mt-1"
                    />

                    <label
                      htmlFor="newPassword"
                      className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mt-2"
                    >
                      Nuova password
                    </label>
                    <InputPsw
                      id="newPassword"
                      onChange={(e) => setNewPassword(e.target.value)}
                      name="newPassword"
                      type="password"
                      placeholder="Nuova password"
                      className="mt-1"
                    />
                  </div>
                  <div className="mt-4 flex flex-row gap-3">
                    <ButtonSecondary
                      onClick={() => setShowModal(false)}
                    >
                      Chiudi
                    </ButtonSecondary>
                    <ButtonPrimary
                      onClick={handleChangePassword}
                      loading={changingPassword}
                    >
                      Cambia password
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      </>
    </div>
  );
};

export default ClientPage;
