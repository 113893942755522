import { MapPinIcon } from "@heroicons/react/24/solid";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import Label from "components/Label/Label";
import { useLoadScript, Marker } from "@react-google-maps/api";
import React, { FC, useEffect, useMemo, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import { usePageAddListingContext } from "contexts/PageAddListingContext";
import { LatLng, getDetails } from "use-places-autocomplete";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import GoogleMapReact from "google-map-react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { selectCurrentExperience, setExperience } from "features/experience/experienceSlice";

export interface PageAddListing2Props { }

const PageAddExperience2: FC<PageAddListing2Props> = () => {
  // TODO: ADD ENV
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCVgu-JWoz3RsKhafHgSffRNKPBngBETxs" as string,
    libraries: ["places"],
  });
  const [selected, setSelected] = useState<any>({
    lat: null,
    lng: null,
    country: null,
    city: null,
    postal_code: null,
    address_components: null,
    vicinity: null,
  });
  const experience = useSelector(selectCurrentExperience);
  const dispatch = useDispatch();

  const handleUpdateExperience = (field: string, value: any) => {
    try {
      dispatch(setExperience({ ...experience, [field]: value }))
    }
    catch (e) {
      console.log(e)
    }
  };

  useEffect(() => {
    if (selected.lat && selected.lng) {
      const address = selected.address_name;
      const city = selected.address_components.find((item: any) => item.types[0] === "locality") || selected.address_components.find((item: any) => item.types[0] === "administrative_area_level_3");
      const country = selected.address_components.find((item: any) => item.types[0] === "country");
      const province = selected.address_components.find((item: any) => item.types[0] === "administrative_area_level_2");
      const postal = selected.address_components.find((item: any) => item.types[0] === "postal_code");
      const region = selected.address_components.find((item: any) => item.types[0] === "administrative_area_level_1");
      const updatedProperty = {
        ...experience,
        location: {
          latitude: selected.lat,
          longitude: selected.lng,
        },
        city: city?.long_name,
        state: country?.short_name,
        postal_code: postal?.short_name,
        address: address,
        country: province?.short_name,
        region: region?.long_name
      };

      dispatch(setExperience(updatedProperty));
    }
  }, [selected])

  if (!isLoaded) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Skeleton height={500} />
      </div>
    )
  }
  return (
    <CommonLayout
      index="02"
      nextHref="/host/new-experience/add-listing-3"
      backtHref="/host/new-experience/add-listing-1"
      canGoNext={selected.lat && selected.lng}
    >
      <>
        <h2 className="text-2xl font-semibold">Dove si svolge l'esperienza?</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          <FormItem label="Indirizzo">
            <PlacesAutocomplete
              setSelected={setSelected}
              place={experience.city || selected.address_components && selected.address_components}
            />
          </FormItem>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
            <FormItem label="Città">
              <Input
                onChange={(e) => handleUpdateExperience("city", e.target.value)}
                value={experience.city || ""}
              />
            </FormItem>
            <FormItem label="Provincia">
              <Input
                onChange={(e) => handleUpdateExperience("country", e.target.value)}
                value={experience.country || ""}
              />
            </FormItem>
            <FormItem label="CAP">
              <Input
                onChange={(e) => handleUpdateExperience("postal_code", e.target.value)}
                value={experience.postal_code || ""}
              />
            </FormItem>
          </div>
          <div>
            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                  {
                    selected.lat && selected.lng ?
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: "AIzaSyCVgu-JWoz3RsKhafHgSffRNKPBngBETxs",
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        defaultZoom={18}
                        center={{
                          lat: selected.lat,
                          lng: selected.lng,
                        }}
                      >
                        <LocationMarker lat={selected.lat} lng={selected.lng} />
                      </GoogleMapReact>
                      :
                      <div>
                        <Skeleton height={300} />
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};
interface PlacesAutocompleteProps {
  setSelected: (location: { lat: number, lng: number, address_components: any, address_name?: string, vicinity: any }) => void;
  place?: any;
}

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({ setSelected, place }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });
    const { lat, lng, } = await getLatLng(results[0]);
    const details = await getDetails({ placeId: results[0].place_id, language: "it" });

    setSelected(
      {
        lat,
        lng,
        vicinity: typeof details !== "string" ? details.vicinity : null,
        address_components: typeof details !== 'string' ? details.address_components : [],
        address_name: typeof details !== 'string' ? details.name : ""
      });
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        type="text"
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className={`border-solid	border-1 block w-full border-neutral-200 h-11 px-4 py-3 text-[1.0004rem] font-normal rounded-2xl focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
        placeholder="Cerca un indirizzo"
      />
      <ComboboxPopover>
        <ComboboxList className="bg-white shadow-md p-2">
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
export default PageAddExperience2;
