import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useEffect } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { useGetAllPropertiesQuery, useGetHighlightsQuery } from "features/property/propertyApiSlice";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { setpropertyList } from "features/property/propertySlice";
import { setBookings } from "features/bookings/bookingsSlice";
import { setEvents } from "features/calendar/calendarSlice";
import SectionTrend from "components/SectionTrend/SectionTrend";
import SectionPartners from "components/SectionPartners/SectionPartners";
import ButtonPrimary from "shared/Button/ButtonPrimary";

function PageHome() {
  const { data: highlights, isLoading: loadingHighlights } = useGetHighlightsQuery({})

  const dispatch = useDispatch();

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28 flex flex-col">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION 
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        />
        
        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        {
          loadingHighlights ?
            <Skeleton count={4} />
            :
            highlights?.properties.length ?
              <div className="relative py-16">
                <BackgroundSection />
                <SectionGridFeaturePlaces
                  stayListings={highlights?.properties}
                  tabs={highlights?.borghi.map((item: TaxonomyType) => item.name) || []}
                />
              </div> : null
        }
        {/* SECTION */}
        <SectionHowItWork />

        {/* SECTION 1 
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Esperienze consigliate da trib you"
            subHeading="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor "
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div>
*/}
        {/* SECTION */}
        <SectionSubscribe2 />

        {/* SECTION 
        <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div>*/}

        {/* SECTION 
                <SectionGridCategoryBox />
        */}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

        <SectionTrend />
        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection className="bg-neutral-100" />
          <div className="relative flex flex-col lg:flex-row items-center">
            <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
              <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
                Incentiviamo viaggi in sicurezza per tutti e tutte
              </h2>
              <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
                Scarica l'app gratuitamente per contribuire alla sicurezza della nostra Community. {" "}
                <a 
                  href="https://www.coyzy.com"
                  className="text-primary-6000 font-medium hover:text-primary-700"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Scopri di più →
                </a>
              </span>
              <div className="flex gap-4 mt-8">
                <a 
                  href="https://apps.apple.com/it/app/coyzy/id1593631054"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img 
                    src="https://digitopoly.org/wp-content/uploads/2016/06/app-store-logo.png" 
                    alt="Download on App Store"
                    className="h-[40px]"
                  />
                </a>
                <a 
                  href="https://play.google.com/store/apps/details?id=com.coyzyapp&gl=IT"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img 
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Google_Play_Store_badge_IT.svg/2560px-Google_Play_Store_badge_IT.svg.png" 
                    alt="Get it on Google Play"
                    className="h-[40px]"
                  />
                </a>
              </div>
            </div>
            <div className="flex-grow">
              <img 
                src="https://is1-ssl.mzstatic.com/image/thumb/PurpleSource112/v4/55/ff/63/55ff63b4-3c2a-28a4-3584-c5218ed699de/6a71482d-6e6b-4330-9538-715058343843_iPhone_11_Pro_Max_-_1.png/460x0w.webp" 
                alt="Coyzy App"
                className="w-full max-w-[200px] mx-auto rounded-3xl shadow-2xl"
              />
            </div>
          </div>
        </div>

        <SectionSliderNewCategories
          heading="Destinazione"
          subHeading="Trova la destinazione perfetta per la tua vacanza."
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        />

        {/* SECTION 
        <SectionVideos />*/}

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>

        <SectionPartners />
      </div>
    </div>
  );
}

export default PageHome;
